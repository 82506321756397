import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const OxyGeneo_Facial_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}> OxyGeneo Facial treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Welcome to Lotus Dental Skin and Hair Care Hospital, where we invite you to experience the transformative benefits of OxyGeneo Facial treatment. Located in the heart of Guntur, our state-of-the-art facility is dedicated to providing advanced skincare solutions tailored to your unique needs and goals. With our commitment to excellence and personalized care, we aim to help you achieve radiant, youthful skin that boosts your confidence and enhances your natural beauty.</p>
                        <h4 style={{color:'#d4296b'}}>What is OxyGeneo Facial?</h4>
                        <p>OxyGeneo Facial is an innovative skincare treatment that combines three essential actions to rejuvenate your complexion: exfoliation, oxygenation, and infusion. This revolutionary approach effectively removes impurities, replenishes moisture, and nourishes the skin with essential nutrients, resulting in a refreshed, glowing appearance.</p>

                    </div>
                    <div className='col-md-5 col-sm-12 mt-2 mb-2 services'>
                        <img src='Oxygeneo-facial.jpg' alt='' style={{width:'90%'}}></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>How Does OxyGeneo Facial Work?</h4>
                <p>The OxyGeneo Facial treatment is a multi-step process designed to enhance skin health and vitality:</p>
                <ul>
                    <li><b>Exfoliation: </b>The treatment begins with gentle exfoliation using a specialized Capsugen capsule infused with natural ingredients. This capsule is massaged onto the skin, removing dead skin cells and surface impurities to reveal a smoother, brighter complexion.</li>
                    <li><b>Oxygenation: </b>As the Capsugen capsule interacts with the skin, it releases carbon dioxide bubbles, creating a gentle carbon dioxide-rich environment. This stimulates oxygenation and blood flow to the skin, promoting improved tone, texture, and overall skin health.</li>
                    <li><b>Infusion: </b>The OxyGeneo device simultaneously delivers nutrient-rich serums deep into the skin through gentle massage and oxygenation. These serums contain hyaluronic acid, peptides, and antioxidants, which hydrate, plump, and nourish the skin from within, resulting in a revitalized and youthful appearance.</li>
                </ul>
                <h4 style={{color:'#d4296b'}}>Benefits of OxyGeneo Facial:</h4>
                <ul>
                    <li>Deep exfoliation to remove dead skin cells and reveal a smoother complexion.</li>
                    <li>Increased oxygenation and blood flow for improved skin tone and texture.</li>
                    <li>Hydration and nourishment to replenish moisture and revitalize the skin.</li>
                    <li>Reduction of fine lines, wrinkles, and other signs of aging.</li>
                    <li>Enhanced absorption of skincare products for maximum effectiveness.</li>
                    <li>Safe and gentle treatment suitable for all skin types.</li>
                </ul>
                <h4 style={{color:'#d4296b'}}>Why Choose Lotus Dental Skin and Hair Care Hospital for OxyGeneo Facial?</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, we are committed to providing exceptional skincare treatments and services that exceed your expectations. Here's why you should choose us for your OxyGeneo Facial treatment:</p>
                <ul>
                    <li><b>Experienced Professionals: </b>Our team of skilled dermatologists and skincare specialists has extensive experience in performing OxyGeneo Facial treatments, ensuring safe and effective results.</li>
                    <li><b>State-of-the-Art Facilities: </b>Our hospital is equipped with advanced technology and state-of-the-art equipment to deliver superior skincare treatments and services.</li>
                    <li><b>Personalized Care: </b>We understand that each patient has unique skincare needs, which is why we customize our OxyGeneo Facial treatments to address your specific concerns and goals.</li>
                    <li><b>Comprehensive Support: </b>From your initial consultation to post-treatment care, we provide comprehensive support and guidance to ensure a positive treatment experience and satisfactory results.</li>
                </ul>
            </div>

            <Footer />
        </>
    )
}

export default OxyGeneo_Facial_Treatment_In_Guntur