import React from 'react'
import DentalHeader from '../Components/DentalHeader'
import DentalFooter from '../Components/DentalFooter'

const Teeth_Extractions_Treatment_In_Guntur = () => {
    return (
        <>
            <DentalHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Teeth extractions treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Lotus Dental Skin and Hair Care Hospital in Guntur provides expert Teeth Extractions services to address various dental concerns and ensure the overall health and well-being of our patients. Our experienced dental professionals perform extractions with precision and care, utilizing advanced techniques and technology to minimize discomfort and promote a smooth recovery.</p>
                        <h4 style={{color:'#d4296b'}}>When Are Teeth Extractions Necessary?</h4>
                        <p>Teeth extractions may be recommended for various reasons, including:</p>
                        <ul>
                            <li><b>Severe Decay: </b>Teeth that are severely decayed and cannot be restored with fillings, crowns, or root canal therapy may need to be extracted to prevent further damage and infection.</li>
                            <li><b>Advanced Gum Disease: </b>Untreated gum disease can lead to bone loss and loosening of the teeth. In severe cases, extraction may be necessary to preserve the health of the surrounding teeth and gums.</li>
                            <li><b>Orthodontic Treatment: </b>Teeth extractions may be necessary as part of orthodontic treatment to create space for proper alignment of the remaining teeth.</li>
                            <li><b>Impacted Wisdom Teeth: </b>Wisdom teeth that are impacted or unable to erupt properly may cause pain, infection, and damage to surrounding teeth. Extraction may be recommended to alleviate symptoms and prevent complications.</li>
                            <li><b>Overcrowding: </b>In cases of severe crowding, extraction of one or more teeth may be necessary to create space for proper alignment and improve overall dental health.</li>
                        </ul>
                    </div>
                    <div className='col-md-5 col-sm-12 services mb-2'>
                        <img src='tooth-extraction.jpeg' alt='' style={{width:'90%'}}></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>Our Approach to Teeth Extractions:</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, we prioritize patient comfort and safety during the extraction process. Our dental professionals begin by thoroughly assessing your oral health and discussing treatment options with you. If extraction is deemed necessary, we will explain the procedure in detail and address any questions or concerns you may have.</p>
                <p>During the extraction procedure, local anesthesia is administered to ensure a pain-free experience. Our skilled dental team uses specialized instruments and techniques to gently remove the tooth from its socket with minimal trauma to the surrounding tissues.</p>
                <p>After the extraction, we provide detailed post-operative instructions to promote proper healing and minimize discomfort. Our goal is to ensure a smooth recovery process and optimal outcomes for every patient.</p>
            </div>

            <DentalFooter />
        </>
    )
}

export default Teeth_Extractions_Treatment_In_Guntur