import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const Chemical_Peels_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Chemical Peels Treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Welcome to Lotus Dental Skin and Hair Care Hospital, your premier destination for advanced skincare solutions in Guntur. Our commitment to excellence and patient satisfaction is evident in our comprehensive range of treatments, including Chemical Peels. This transformative procedure offers a non-invasive solution to address various skin concerns and reveal smoother, more radiant skin.</p>
                        <h4 style={{color:'#d4296b'}}>Understanding Chemical Peels Treatment:</h4>
                        <p>Chemical Peels are cosmetic treatments that involve the application of a chemical solution to the skin, which causes exfoliation and eventually peeling of the outermost layers. This process stimulates cell turnover, revealing fresh, new skin underneath and improving the overall appearance of the complexion. Chemical Peels can be customized to target specific skin concerns, such as acne, hyperpigmentation, fine lines, and uneven texture.</p>

                    </div>
                    <div className='col-md-5 col-sm-12 mt-2 mb-2 services'>
                        <img src='chemical-peel.jpg' alt='' style={{width:'90%'}}></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>How Does it Work?</h4>
                <p>The Chemical Peels Treatment process begins with a thorough consultation with one of our experienced dermatologists. Based on your skin type, concerns, and desired outcomes, a customized treatment plan will be developed. During the procedure, the chemical solution is carefully applied to the skin and left on for a specific period. Depending on the depth of the peel, you may experience mild tingling or a sensation of warmth. Over the following days, the outer layers of the skin will gradually peel away, revealing smoother, more youthful-looking skin underneath.</p>
                <h4 style={{color:'#d4296b'}}>Benefits of Chemical Peels Treatment:</h4>
                <p>Chemical Peels Treatment offers a multitude of benefits for the skin, including:</p>
                <ul>
                    <li>Exfoliation of dead skin cells to reveal a brighter, more even complexion.</li>
                    <li>Reduction of acne breakouts and congestion by unclogging pores and reducing oil production.</li>
                    <li>Improvement of skin texture and tone, resulting in smoother, softer skin.</li>
                    <li>Reduction of hyperpigmentation, including sunspots, age spots, and melasma.</li>
                    <li>Softening of fine lines and wrinkles, leading to a more youthful appearance.</li>
                    <li>Stimulated collagen production for firmer, more resilient skin.</li>
                </ul>
                <h4 style={{color:'#d4296b'}}>Why Choose Lotus Dental Skin and Hair Care Hospital?</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, we prioritize patient safety and satisfaction above all else. Our team of skilled dermatologists and skincare specialists has extensive experience in performing Chemical Peels, ensuring safe and effective results for every patient. We use only the highest quality products and adhere to strict safety protocols to ensure a comfortable and positive treatment experience.</p>

            </div>

            <Footer />
        </>
    )
}

export default Chemical_Peels_Treatment_In_Guntur