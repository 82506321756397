import React from 'react'
import { Link } from 'react-router-dom'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'


const Laser_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{  display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Laser TREATMENT IN LOTUS DENTAL SKIN AND HAIR care</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container mt-3'>
                <div className='row'>
                    <p>Experience the power of laser technology at Lotus Dental Skin and Hair Care in Guntur. Our advanced laser treatments offer safe and effective solutions for various skin concerns. From acne scars to pigmentation issues, our skilled dermatologists use state-of-the-art lasers to rejuvenate your skin and enhance your natural beauty. With precision and expertise, we target specific areas to deliver optimal results with minimal downtime. Whether you're seeking skin resurfacing, hair removal, or tattoo removal, our personalized approach ensures your comfort and satisfaction. Trust Lotus Dental Skin and Hair Care for innovative laser treatments that transform your skin and elevate your confidence. Schedule your consultation today and discover the beauty of laser therapy.</p>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='Acne-Scar-Laser-Treatment.jpg' alt='' className='treatmentImages'></img>
                            <a href='/Acne_And_Acne_Marks_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Acne & Acne Marks</b></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='pigmentation.jpg' alt='' className='treatmentImages'></img>
                            <a href='Pigmentation_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Pigmentation</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='freckles.jpeg' alt='' className='treatmentImages'></img>
                            <a href='Freckles_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Freckless</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='wrinkled.jpg' alt='' className='treatmentImages'></img>
                            <a href='Wrinkless_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Wrinkles</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='dark circles.jpeg' alt='' className='treatmentImages'></img>
                            <a href='Under_Dark_Circles_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Under Dark Circles</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='Laser-Skin-Tightening.jpg' alt='' className='treatmentImages'></img>
                            <a href='Skin_Tightening_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Skin Tightening</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='laser-hair-removal.webp' alt='' className='treatmentImages'></img>
                            <a href='Hair_Removal_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Hair Removal</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='tattoo-removal.jpeg' alt='' className='treatmentImages'></img>
                            <a href='Tattoo_Remova_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Tatoo Removal</b></a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Laser_Treatment_In_Guntur