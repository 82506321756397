import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'

const Skin_and_hair_care_images = () => {
    return (
        <>
        <SkinCareHeader/>
        <div className='topmargin'></div>
          <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
            <div className="col text-center strip1" style={{ color: 'white' }}>
              <div className="row mt-5" >
                <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Images</h3>
              </div>
              <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
              </ul>
            </div>
          </div>
          <div className='container'>
            <h4 className='text-center p-5'>Images will be updated soon...</h4>
          </div>
        </>
      )
}

export default Skin_and_hair_care_images