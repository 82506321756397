import React from 'react'
import DentalHeader from './DentalHeader'
import Carousel from 'react-bootstrap/Carousel';
import Footer from './Footer';
import { Link } from 'react-router-dom';

import {
    MDBCard,
    MDBCardBody,
    MDBCarousel,
    MDBCarouselItem,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
} from "mdb-react-ui-kit";
import DentalFooter from './DentalFooter';

const Dental_Care_Home = () => {
    return (
        <>
            <DentalHeader />
            <div className='topmargin'></div>
            <Carousel data-bs-theme="dark">
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="dentalBanner.jpg"
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                        <h5>First slide label</h5>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="dentalbaner2.jpg"
                        alt="Second slide"
                    />
                    {/* <Carousel.Caption>
                        <h5>Second slide label</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="DentalBanner3.jpg"
                        alt="Third slide"
                    />
                    {/* <Carousel.Caption>
                        <h5>Third slide label</h5>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>

            <div className='container'>
                <h2 style={{ color: '#d4296b' }}>LOTUS DENTAL SKIN AND HAIR CARE IN GUNTUR</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '40%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px' }} className='mb-3'></div>
                <div className='row'>
                    <div className='col-md-5 col-sm-12 mt-3 mb-3'>
                        <img src='Lotus Hospital pic.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>At Lotus Dental Skin and Hair Care, we believe everyone deserves to look and feel their best. Conveniently located in Guntur, our state-of-the-art facility offers a comprehensive range of services to meet all your dental, skin, and hair care needs. Our dedicated team of professionals is committed to providing personalized care in a comfortable and welcoming environment.</p>
                        <p style={{ textAlign: 'justify' }}>We offer comprehensive dental services including routine check-ups, cleanings, fillings, extractions, and advanced cosmetic procedures like teeth whitening, veneers, and smile makeovers. Our orthodontic treatments include traditional braces and Invisalign, while our endodontic services cover root canal treatments and retreatments. For gum health, we provide gum disease treatment and surgical options for severe cases. Our prosthodontic services include crowns, bridges, dentures, and dental implants. We also offer specialized pediatric dentistry to ensure your child's oral health is maintained from an early age. Our oral surgery services include wisdom teeth removal and oral biopsies.</p>
                        {/* <p style={{ textAlign: 'justify' }}></p> */}
                    </div>
                </div>
            </div>

            <div className='container'>
                <h2 style={{ color: '#d4296b' }}>Treatments</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '10%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                <div className='row'>
                <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='dental-implants1.jpg' className='treatmentImages'></img>
                            <a href='Implants_Treatment_In_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Veneers</h6></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='Zoom_Teeth_Whitening_Image.jpg' className='treatmentImages'></img>
                            <a href='/Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur' style={{ textDecoration: 'none' }}><h6 className='mt-3' style={{ color: '#d4296b',}}>Advanced Zoom Teeth Whitening</h6></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='SmileDesign.jpeg' className='treatmentImages'></img>
                            <a href='/Smile_Designing_Treatment_In_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Smile Designing</h6></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='root-canal-treatment.jpg' className='treatmentImages'></img>
                            <a href='Root_Canal_Treatment_in_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Root canal treatment</h6></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='full-mouth-rehabilitation.jpeg' className='treatmentImages'></img>
                            <a href='/Full_Mouth_Rehabilitation_In_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Full Mouth Rehabilitation</h6></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='tooth-extraction.jpeg' className='treatmentImages'></img>
                            <a href='Teeth_Extractions_Treatment_In_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Teeth Extraction</h6></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='Laser Dentistry.webp' className='treatmentImages'></img>
                            <a href='Laser_Dentistry_Treatment_In_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Laser Dentistry</h6></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-3 mb-3' style={{height:'90%'}}>
                            <img src='braces.webp' className='treatmentImages'></img>
                            <a href='/Invisible_Aligners_And_Braces_Treatment_In_Guntur' style={{ textDecoration: 'none' }}><h6 style={{ color: '#d4296b' }}>Invisible Aligners & Braces</h6></a>
                        </div>
                    </div>

                   
                </div>
            </div>

            <div className='container'>
                <h2 style={{ color: '#d4296b' }}>Get To Know Our Team Of Experts</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '30%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                <p style={{ textAlign: 'center' }}>Our team of experienced and accomplished doctors always give their best in providing ethical, modern and evidence based patient-centric treatment and always ensure health and safety of our patients.</p>
            </div>

            <div className='container' data-aos="fade-up-right" data-aos-duration="3000" >
                <div className='row'>
                    <div className='col-md-6 '  >
                        <div className="card mb-3 " style={{ border: '4px solid #fff', boxShadow: '0 0 20px #ccc', borderRadius: '10px', height: '100%' }}>
                            <div className="row g-0">
                                <div className="col-md-4 mt-3">
                                    <img src="PawanSreeHarish.jpg" className="img-fluid rounded-start" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <p className="card-text" style={{ fontSize: '14px' }}>Dr. Pawan Sree Harish, BDS, FAD, is a Cosmetic Dental Surgeon specializing in aesthetic dental procedures. His qualifications include a Bachelor of Dental Surgery (BDS) 
                                        </p>
                                        <Link to='/DrkPawanSreeHarish'><button className='btn mt-2'>Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-md-6'>
                        <div className="card mb-3" style={{ border: '4px solid #fff', boxShadow: '0 0 20px #ccc', borderRadius: '10px', height: '100%' }}  >
                            <div className="row g-0">
                                <div className="col-md-4 mt-3">
                                    <img src="Nikitha.jpg" className="img-fluid rounded-start" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <p className="card-text" style={{ fontSize: '15px' }}>Dr. NV Nikhitha, BDS, FAAD, is a Root Canal Specialist with qualifications that include a Bachelor of Dental Surgery (BDS) and a Fellowship in Aesthetic and Advanced Dentistry (FAAD).</p>
                                        <Link to='/DrNVNikhitha'><button className='btn '>Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* testmonials */}

            <div className='container'>
                <h2 style={{color:'#d4296b'}}>Testmonials</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '10%', marginLeft: 'auto', marginRight: 'auto' }} className=''></div>
                <div className=''>
                    <MDBContainer fluid className=" gradient-custom">
                        <MDBRow className="d-flex justify-content-center">
                            <MDBCol md="12">
                                <div className="text-center mb-4 pb-2">
                                    <MDBIcon fas icon="quote-left" size="3x" className="text-white" />
                                </div>
                                <MDBCard>
                                    <MDBCardBody className="px-4 py-5">
                                        <MDBCarousel showIndicators showControls dark>

                                            <MDBCarouselItem className="active">
                                                <MDBRow className="d-flex justify-content-center">
                                                    <MDBCol lg="10" xl="8">
                                                        <MDBRow>
                                                            <MDBCol
                                                                lg="4"
                                                                className="d-flex justify-content-center"
                                                            >
                                                                <img
                                                                    src="Profile-PNG-Clipart.png"
                                                                    className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                                                    alt="woman avatar"
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol
                                                                md="9"
                                                                lg="7"
                                                                xl="8"
                                                                className="text-center text-lg-start mx-auto mx-lg-0"
                                                            >
                                                                <h4 className="mb-4">
                                                                   Mohan Rao Pasupuleti
                                                                </h4>
                                                                <p className="mb-0 pb-3">
                                                                Very good best treatment by Lotus dental Doctors. I will advise my friend's also to go to lotus dental care. They are completing the treatment with in sufficient time. Their negotiations are also very nice.
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem>
                                                <MDBRow className="d-flex justify-content-center">
                                                    <MDBCol lg="10" xl="8">
                                                        <MDBRow>
                                                            <MDBCol
                                                                lg="4"
                                                                className="d-flex justify-content-center"
                                                            >
                                                                <img
                                                                    src="Profile-PNG-Clipart.png"
                                                                    className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                                                    alt="woman avatar"
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol
                                                                md="9"
                                                                lg="7"
                                                                xl="8"
                                                                className="text-center text-lg-start mx-auto mx-lg-0"
                                                            >
                                                                <h4 className="mb-4">
                                                                    Ravi Shankar Chandu
                                                                </h4>
                                                                <p className="mb-0 pb-3">
                                                                Lotus dental clinic gave the best treatment and clar explanation about the treatment thank you Dr. Pavan sir and Dr. Nikhitha madam. Best dental clinic in guntur i suggest my family members and friends.
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem>
                                                <MDBRow className="d-flex justify-content-center">
                                                    <MDBCol lg="10" xl="8">
                                                        <MDBRow>
                                                            <MDBCol
                                                                lg="4"
                                                                className="d-flex justify-content-center"
                                                            >
                                                                <img
                                                                    src="Profile-PNG-Clipart.png"
                                                                    className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                                                    alt="woman avatar"
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol
                                                                md="9"
                                                                lg="7"
                                                                xl="8"
                                                                className="text-center text-lg-start mx-auto mx-lg-0"
                                                            >
                                                                <h4 className="mb-4">
                                                                    Dr.Samali Maity
                                                                </h4>
                                                                <p className="mb-0 pb-3">
                                                                Received my dental treatment here. Thanks to Dr. Pawan sir and Dr. Nikitha ma'am. I'm fully satisfied with the treatment they have given. Plus they will explain everything about the procedure and how to maintain the teeth post procedure in detail. Recommended to anyone who's looking for quality dental care. Keep up the good work👍🏼
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCarouselItem>

                                        </MDBCarousel>
                                    </MDBCardBody>
                                </MDBCard>
                                <div className="text-center mt-4 pt-2">
                                    <MDBIcon fas icon="quote-right" size="3x" className="text-white" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>
            <DentalFooter/>
        </>
    )
}

export default Dental_Care_Home