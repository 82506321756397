import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const Acne_treatment_in_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Acne treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Acne can be a persistent and frustrating condition, affecting not just your skin but also your confidence. At Lotus Dental Skin and Hair Care Hospital in Guntur, we understand the impact of acne on your life, and we're here to help. Our comprehensive acne treatment options are designed to effectively target acne at its root cause, giving you clear, radiant skin you can feel confident about.</p>
                        <h4 style={{ color: '#d4296b' }}>Understanding Acne:</h4>
                        <p style={{ textAlign: 'justify' }}>Acne is a common skin condition characterized by the presence of pimples, blackheads, whiteheads, and often, inflammation. It can occur due to a variety of factors, including hormonal changes, genetics, lifestyle choices, and skincare habits.</p>
                        <h4 style={{ color: '#d4296b' }}>Our Approach to Acne Treatment:</h4>
                        <p style={{ textAlign: 'justify' }}>At Lotus Dental Skin and Hair Care Hospital, we take a personalized approach to acne treatment, addressing the unique needs of each patient. Our experienced dermatologists will assess your skin condition, medical history, and lifestyle factors to develop a customized treatment plan tailored to your specific needs.</p>
                        <h4 style={{ color: '#d4296b' }}>Advanced Treatment Solutions:</h4>
                    </div>
                    <div className='col-md-5 col-sm-12 services'>
                        <img src='AcneTreatment.webp' alt=''></img>
                    </div>
                </div>
                <ul style={{ textAlign: 'justify', marginLeft: '2%' }}>
                    <li><b>Topical Medications: </b>We offer a range of prescription-strength topical medications, including retinoids, benzoyl peroxide, and salicylic acid, to help unclog pores, reduce inflammation, and prevent new acne breakouts.</li>
                    <li><b>Oral Medications: </b>In some cases, oral medications such as antibiotics, hormonal treatments, or isotretinoin may be recommended to target acne from within and reduce its severity.</li>
                    <li><b>Chemical Peels: </b>Our medical-grade chemical peels are formulated to exfoliate the skin, unclog pores, and reduce acne-related inflammation, resulting in smoother, clearer skin.</li>
                    <li><b>Microdermabrasion: </b>Microdermabrasion gently exfoliates the outer layer of the skin, removing dead skin cells, excess oil, and impurities that can contribute to acne breakouts.</li>
                    <li><b>Laser Therapy: </b>Laser treatments can target acne-causing bacteria, reduce inflammation, and stimulate collagen production for clearer, healthier-looking skin.</li>
                    <li><b>Acne Scar Treatment: </b>For those dealing with acne scarring, we offer advanced treatments such as microneedling, dermal fillers, and laser resurfacing to minimize the appearance of scars and restore smooth skin texture.</li>
                </ul>
                <h4 style={{ color: '#d4296b' }}>Comprehensive Care and Support:</h4>
                <p style={{ textAlign: 'justify' }}>Throughout your acne treatment journey, our compassionate team will provide ongoing support, guidance, and education to help you achieve and maintain clear, healthy skin. We believe in empowering our patients with the knowledge and tools they need to take control of their skin health and confidence.</p>

            </div>

            <Footer />
        </>
    )
}

export default Acne_treatment_in_Guntur