import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader';
import Footer from '../Components/Footer';

const Lips_Treatment_in_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Lips TREATMENT IN LOTUS DENTAL SKIN AND HAIR care</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container mt-3'>
                <div className='row' style={{justifyContent:'center'}}>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                            <img src='lipNutralization.jpg' alt='' style={{width:'100%'}}></img>
                            <a href='Lip_Neutralisation_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Lip Neutralisation</b></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%' }}>
                            <img src='lipTint.png' alt='' style={{width:'100%',marginTop:'0%'}}></img>
                            <a href='Semi_Permanent_Lip_Tint_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Semi-Permanent Lip Tint</b></a>
                        </div>
                    </div>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default Lips_Treatment_in_Guntur