import React from 'react'
import DentalHeader from '../Components/DentalHeader'
import DentalFooter from '../Components/DentalFooter'


const impactions_treatment_In_Guntur = () => {
    return (
        <>
            <DentalHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Mandibular and maxillary surgeries</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Impactions treatment at Lotus Dental Skin and Hair Care Hospital in Guntur refers to the specialized care provided to patients experiencing dental impactions. Dental impaction occurs when a tooth fails to fully emerge through the gums due to obstruction by surrounding tissue, bone, or other teeth. This condition commonly affects wisdom teeth (third molars) but can also occur with other teeth in the mouth.</p>
                        <p>The impactions treatment offered at Lotus Dental Skin and Hair Care Hospital involves a comprehensive approach to address impacted teeth effectively and restore oral health. It typically begins with a thorough evaluation, including digital X-rays and imaging, to assess the position and condition of the impacted teeth. Based on the severity and location of the impaction, the dental team develops personalized treatment plans tailored to each patient's needs.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services mb-2 mt-2'>
                        <img src='impactions.jpeg' alt='' style={{ width: '90%' }}></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>Treatment options for dental impactions may include:</h4>
                <ul>
                    <li>Extraction: For severely impacted teeth, extraction may be recommended to alleviate pain, prevent complications, and preserve oral health. The extraction procedure is performed using gentle techniques and advanced tools to minimize discomfort and promote healing.</li>
                    <li>Surgical Intervention: In cases of complex impactions or impacted teeth located deep within the jawbone, surgical intervention may be necessary. Oral and maxillofacial surgeons are trained to perform surgical procedures to access and remove impacted teeth safely and effectively.</li>
                </ul>
                <p>Throughout the impactions treatment process, patient comfort and care are prioritized. Sedation options, including local anesthesia and conscious sedation, may be provided to ensure a comfortable and stress-free experience for patients.</p>
                <p>The goal of impactions treatment at Lotus Dental Skin and Hair Care Hospital is to relieve symptoms associated with dental impaction, such as pain and swelling, and restore optimal oral function and health. Patients can expect personalized care and attention from a skilled dental team dedicated to achieving successful outcomes and improving overall well-being.</p>
            </div>

            <DentalFooter />
        </>
    )
}

export default impactions_treatment_In_Guntur