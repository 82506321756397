import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const Customized_Skin_Care_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Customised Skin Care treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Your skin is unique, and it deserves personalized care that addresses its specific needs and concerns. At Lotus Dental Skin and Hair Care Hospital in Guntur, we believe in the power of customized skin care solutions to help you achieve healthy, radiant skin. Our experienced dermatologists and skincare experts offer a range of tailored treatments designed to nourish, rejuvenate, and protect your skin, so you can look and feel your best every day.</p>
                        <h4 style={{ color: '#d4296b' }}>Customized Skin Care Consultation:</h4>
                        <p>Your journey to beautiful skin begins with a thorough consultation with one of our skincare specialists. During this comprehensive assessment, we'll take the time to understand your skin type, concerns, lifestyle factors, and treatment goals. By gaining insight into your unique skin profile, we can develop a personalized treatment plan that addresses your specific needs and helps you achieve optimal results.</p>
                        <h4 style={{ color: '#d4296b' }}>Tailored Treatment Options:</h4>

                    </div>
                    <div className='col-md-5 col-sm-12 mt-2 mb-2 services'>
                        <img src='customizedSkinCare.webp' alt='' style={{width:'90%'}}></img>
                    </div>
                </div>
                <ul>
                    <li><b>HydraFacial: </b>Experience the ultimate in skin hydration and rejuvenation with our signature HydraFacial treatment. This advanced facial combines cleansing, exfoliation, extraction, hydration, and antioxidant protection to leave your skin looking instantly refreshed and glowing.</li>
                    <li><b>Customized Facials: </b>Choose from a variety of customized facial treatments tailored to your skin type and concerns, including acne-fighting facials, brightening facials, anti-aging facials, and more. Our skincare experts will select the most suitable ingredients and techniques to address your unique needs.</li>
                    <li><b>Chemical Peels: </b>Renew your skin's texture and appearance with our medical-grade chemical peels. These customizable treatments help exfoliate the outer layer of dead skin cells, revealing smoother, more radiant skin underneath. Whether you're dealing with acne, hyperpigmentation, or signs of aging, we have a peel that's right for you.</li>
                    <li><b>Microneedling: </b>Stimulate collagen production and improve skin texture with microneedling therapy. This minimally invasive treatment uses tiny needles to create controlled micro-injuries, triggering your skin's natural healing process and promoting the growth of healthy new tissue.</li>
                    <li><b>LED Light Therapy: </b>Harness the power of light to rejuvenate your skin and target specific concerns such as acne, inflammation, and signs of aging. Our LED light therapy treatments utilize different wavelengths of light to penetrate deep into the skin and stimulate cellular activity.</li>
                    <li><b>Customized Skincare Regimen: </b>Our skincare experts will curate a personalized skincare regimen tailored to your skin type, concerns, and treatment goals. From cleansers and moisturizers to serums and sunscreen, we'll recommend the best products to help you maintain healthy, glowing skin between treatments.</li>
                </ul>
            </div>

            <Footer />
        </>
    )
}

export default Customized_Skin_Care_Treatment_In_Guntur