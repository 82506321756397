import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const Semi_Permanent_Lip_Tint_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Semi-Permanent Lip Tint treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                     <p>Lotus Dental Skin and Hair Care Hospital in Guntur offers Semi-Permanent Lip Tint, a specialized procedure designed to enhance the natural beauty of your lips with long-lasting color.</p>
                     <h4 style={{color:'#d4296b'}}>Procedure Overview:</h4>
                     <p>Semi-Permanent Lip Tint is a cosmetic treatment aimed at adding color and definition to the lips. Unlike traditional lipstick, which requires frequent reapplication, semi-permanent lip tinting provides a lasting solution for beautifully tinted lips.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services'>
                        <img src='liptint1.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>Customized Consultation:</h4>
                <p>Our experienced practitioners conduct personalized consultations to understand your desired lip color and aesthetic preferences. We take into account factors such as your skin tone, lip shape, and overall facial features to create a customized lip tinting plan.</p>
                <h4 style={{color:'#d4296b'}}>Pigment Selection:</h4>
                <p>Based on your consultation, we select high-quality pigments in shades that complement your natural lip color and enhance your overall appearance. We offer a range of colors to suit every preference, from subtle nude tones to bold and vibrant hues.</p>
                <h4 style={{color:'#d4296b'}}>Precise Application:</h4>
                <p>During the Semi-Permanent Lip Tint procedure, our skilled practitioners use precise techniques to apply the pigment to your lips. We carefully outline the lip border and fill in the color to create a defined and natural-looking result.</p>
                <h4 style={{color:'#d4296b'}}>Long-Lasting Results:</h4>
                <p>Semi-Permanent Lip Tint offers long-lasting color that can withstand daily activities such as eating, drinking, and talking. With proper care and maintenance, the tint can last for several months, providing you with consistently beautiful lips.</p>
                <h4 style={{color:'#d4296b'}}>Enhanced Lip Definition:</h4>
                <p>By adding color and definition to the lips, Semi-Permanent Lip Tint can enhance the appearance of your lip contour, creating the illusion of fuller and more shapely lips.</p>
                <h4 style={{color:'#d4296b'}}>Low Maintenance:</h4>
                <p>Unlike traditional lipstick, which requires frequent touch-ups throughout the day, semi-permanent lip tinting reduces the need for constant reapplication. This makes it an ideal choice for individuals with busy lifestyles who desire effortless beauty.</p>
                <h4 style={{color:'#d4296b'}}>Safe and Comfortable:</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, your safety and comfort are our top priorities. We use high-quality pigments and adhere to strict sterilization and hygiene protocols to ensure a safe and hygienic treatment environment.</p>
               
            </div>

            <Footer />
        </>
    )
}

export default Semi_Permanent_Lip_Tint_Treatment_In_Guntur