import React from 'react'
import Footer from '../Components/Footer'
import SkinCareHeader from '../Components/SkinCareHeader'

const Medi_facial_treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Medi facial TREATMENT IN LOTUS DENTAL SKIN AND HAIR care</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container mt-3'>
                <div className='row'>
                    <p>
                        Indulge in the ultimate skin pampering experience with Medi Facial treatments at Lotus Dental Skin and Hair Care in Guntur. Our Medi Facial treatments combine the luxury of a spa facial with the expertise of medical-grade skincare to deliver unparalleled results. Tailored to your unique skin needs, our treatments address concerns such as acne, dehydration, and aging, leaving your skin refreshed and radiant. With a blend of advanced techniques and premium skincare products, our skilled aestheticians provide a rejuvenating experience that revitalizes your complexion from within. Trust Lotus Dental Skin and Hair Care for Medi Facial treatments that enhance your natural beauty and leave you glowing. Schedule your appointment today and indulge in the luxury of radiant skin.</p>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                            <img src='hydrafacial.jpg' alt='' className='treatmentImages'></img>
                            <a href='Hydra_Facial_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Hydra Facial</b></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                            <img src='Oxygeneo-facial.jpg' alt='' className='treatmentImages'></img>
                            <a href='OxyGeneo_Facial_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Oxygeneo-Facial</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                            <img src='Laser_Carbon_Peel.avif' alt='' className='treatmentImages'></img>
                            <a href='Carbon_Peel_Laser_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Carbon Peel Laser</b></a>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                            <img src='chemical-peel.jpg' alt='' className='treatmentImages'></img>
                            <a href='Chemical_Peels_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Chemical Peels</b></a>
                        </div>
                    </div>

                    {/* <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                            <img src='' alt='' className='treatmentImages'></img>
                            <a href='' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>tr..Somal Fcial</b></a>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Medi_facial_treatment_In_Guntur
