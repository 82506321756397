import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'


const Lip_Neutralisation_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Lip Neutralisation treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Lotus Dental Skin and Hair Care Hospital in Guntur is proud to offer Lip Neutralisation treatment, a specialized procedure aimed at enhancing the natural beauty of your lips and addressing pigmentation irregularities.</p>
                        <h4 style={{color:'#d4296b'}}>Procedure</h4>
                        <p> Lip Neutralisation is a cosmetic procedure designed to correct pigmentation issues on the lips, such as dark spots, uneven coloring, or hyperpigmentation. This treatment involves the application of specialized pigments to neutralize or balance out the natural color of the lips, resulting in a more even and uniform appearance.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services'>
                        <img src='lipNutralization.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>Personalized Approach</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, we understand that each patient is unique. Our experienced practitioners conduct thorough consultations to assess your concerns and goals, evaluating the current condition of your lips and discussing your desired outcome.</p>
                <p>Based on the consultation, we create a personalized treatment plan tailored to your specific lip pigmentation issues and aesthetic preferences</p>
                <p> During the Lip Neutralisation procedure, our skilled practitioners meticulously apply specialized pigments to the lips using precise techniques. By targeting areas of uneven pigmentation and blending the pigments, we create a more uniform and balanced lip color.</p>
                <h4 style={{color:'#d4296b'}}>Natural-Looking Results</h4>
                <p>Lip Neutralisation aims to achieve natural-looking results that enhance the beauty of your lips without appearing overdone or artificial. Whether you prefer subtle corrections or more dramatic enhancements, our practitioners can customize the treatment to suit your preferences.</p>
                <h4 style={{color:'#d4296b'}}>Benefits</h4>
                <p>The benefits of Lip Neutralisation include improved lip appearance, enhanced lip definition, and long-lasting results.</p>
            </div>

            <Footer />
        </>
    )
}

export default Lip_Neutralisation_Treatment_In_Guntur