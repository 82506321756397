import React from 'react'
import DentalHeader from '../Components/DentalHeader'
import DentalFooter from '../Components/DentalFooter'


const DentalBlogs = () => {
  return (
    <>
      <DentalHeader />
      <div className='topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Blogs</h3>
          </div>
          <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
          </ul>
        </div>
      </div>

      {/* blogs styles are in App.css */}
      
      <div className='container mt-5 mb-5'>    
          <div className='row'>
            <div className='col-md-4 col-sm-12'>
              <div className='card' id='BlogCard'>
                  <img src='cavities.png' alt='Image'></img>
                  <a href='Five_Common_Signs_and_Symptoms_of_a_Tooth_Cavity' style={{textDecoration:'none',color:'black'}}><h6 className='p-3 text-center'>5 Common Signs and Symptoms of Tooth Cavity</h6></a>
              </div>
            </div>
          </div>
      </div>
      <DentalFooter />
    </>
  )
}

export default DentalBlogs