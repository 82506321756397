
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Components/Layout';
import Skin_And_Hair_Care_Home from './Components/Skin_And_Hair_Care_Home';
import Skin_Care_specialist_in_guntur from './skinCareComponents/Skin_Care_specialist_in_guntur';
import Hair_Treatment_In_Guntur from './HairComponents/Hair_Treatment_In_Guntur';
import Laser_Treatment_In_Guntur from './LaserComponent/Laser_Treatment_In_Guntur';
import Medi_facial_treatment_In_Guntur from './cosmetologyComponent/Medi_facial_treatment_In_Guntur';
import PMU_Treatment_In_Guntur from './skinCareComponents/PMU_Treatment_In_Guntur';
import Eyebrows_Treatment_in_Guntur from './skinCareComponents/Eyebrows_Treatment_in_Guntur';
import Lips_Treatment_in_Guntur from './skinCareComponents/Lips_Treatment_in_Guntur';
import Dental_Care_Home from './Components/Dental_Care_Home';
import DentalContact from './ContactComponent/DentalContact';
import Microdermabrasion_Treatment_In_Guntur from './skinCareComponents/Microdermabrasion_Treatment_In_Guntur';
import Acne_treatment_in_Guntur from './skinCareComponents/Acne_treatment_in_Guntur';
import Customized_Skin_Care_Treatment_In_Guntur from './skinCareComponents/Customized_Skin_Care_Treatment_In_Guntur';
import Wart_Removal_Treatment_in_Guntur from './skinCareComponents/Wart_Removal_Treatment_in_Guntur';
import Melasma_Management_Treatment_In_Guntur from './skinCareComponents/Melasma_Management_Treatment_In_Guntur';
import PRP_Treatment_In_Guntur from './HairComponents/PRP_Treatment_In_Guntur';
import Biotin_And_PRP_treatment_in_Guntur from './HairComponents/Biotin_And_PRP_treatment_in_Guntur';
import GFC_Hair_Treatment_in_Guntur from './HairComponents/GFC_Hair_Treatment_in_Guntur';
import Alopecia_Areata_treatment_in_Guntur from './HairComponents/Alopecia_Areata_treatment_in_Guntur';
import Stem_Cell_Therapy_in_Guntur from './HairComponents/Stem_Cell_Therapy_in_Guntur';
import Hair_Transplantation_treatment_in_Guntur from './HairComponents/Hair_Transplantation_treatment_in_Guntur';
import Dandruff_Treatment_in_Guntur from './HairComponents/Dandruff_Treatment_in_Guntur';
import Acne_And_Acne_Marks_Treatment_In_Guntur from './LaserComponent/Acne_And_Acne_Marks_Treatment_In_Guntur';
import Pigmentation_Treatment_in_Guntur from './LaserComponent/Pigmentation_Treatment_in_Guntur';
import Freckles_Treatment_in_Guntur from './LaserComponent/Freckles_Treatment_in_Guntur';
import Wrinkless_Treatment_In_Guntur from './LaserComponent/Wrinkless_Treatment_In_Guntur';
import Under_Dark_Circles_Treatment_In_Guntur from './LaserComponent/Under_Dark_Circles_Treatment_In_Guntur';
import Skin_Tightening_Treatment_In_Guntur from './LaserComponent/Skin_Tightening_Treatment_In_Guntur';
import Hair_Removal_Treatment_in_Guntur from './LaserComponent/Hair_Removal_Treatment_in_Guntur';
import Tattoo_Remova_Treatment_in_Guntur from './LaserComponent/Tattoo_Remova_Treatment_in_Guntur';
import Hydra_Facial_Treatment_In_Guntur from './cosmetologyComponent/Hydra_Facial_Treatment_In_Guntur';
import OxyGeneo_Facial_Treatment_In_Guntur from './cosmetologyComponent/OxyGeneo_Facial_Treatment_In_Guntur';
import Carbon_Peel_Laser_Treatment_In_Guntur from './cosmetologyComponent/Carbon_Peel_Laser_Treatment_In_Guntur';
import Chemical_Peels_Treatment_In_Guntur from './cosmetologyComponent/Chemical_Peels_Treatment_In_Guntur';
import PDO_COG_Threads_Treatment_In_Guntur from './AestheticComponent/PDO_COG_Threads_Treatment_In_Guntur';
import Lip_Fillers_Treatment_In_Guntur from './AestheticComponent/Lip_Fillers_Treatment_In_Guntur';
import Under_Eye_Fillers_Treatment_In_Guntur from './AestheticComponent/Under_Eye_Fillers_Treatment_In_Guntur';
import Botox_Treatment_In_Guntur from './AestheticComponent/Botox_Treatment_In_Guntur';
import Barbie_Botox_treatment_In_Guntur from './AestheticComponent/Barbie_Botox_treatment_In_Guntur';
import Baby_Botox_Treatment_In_Guntur from './AestheticComponent/Baby_Botox_Treatment_In_Guntur';
import Nano_needling_Treatment_In_Guntur from './AestheticComponent/Nano_needling_Treatment_In_Guntur';
import IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur from './IV-THERAPY/IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur';
import IV_Lipolysis_Treatment_in_Guntur from './IV-THERAPY/IV_Lipolysis_Treatment_in_Guntur';
import Microblading_Treatment_In_Guntur from './EyebrowsComponent/Microblading_Treatment_In_Guntur';
import Micro_Pigmentation_Treatment_In_Guntur from './EyebrowsComponent/Micro_Pigmentation_Treatment_In_Guntur';
import Ombre_Powder_Brows_Treatment_In_Guntur from './EyebrowsComponent/Ombre_Powder_Brows_Treatment_In_Guntur';
import Lip_Neutralisation_Treatment_In_Guntur from './LipsComponent/Lip_Neutralisation_Treatment_In_Guntur';
import Semi_Permanent_Lip_Tint_Treatment_In_Guntur from './LipsComponent/Semi_Permanent_Lip_Tint_Treatment_In_Guntur';
import SkinCareContact from './ContactComponent/SkinCareContact';
import DentalFooter from './Components/DentalFooter';
import Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur from './DentalServices/Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur';
import Smile_Designing_Treatment_In_Guntur from './DentalServices/Smile_Designing_Treatment_In_Guntur';
import Root_Canal_Treatment_in_Guntur from './DentalServices/Root_Canal_Treatment_in_Guntur';
import Full_Mouth_Rehabilitation_In_Guntur from './DentalServices/Full_Mouth_Rehabilitation_In_Guntur';
import Teeth_Extractions_Treatment_In_Guntur from './DentalServices/Teeth_Extractions_Treatment_In_Guntur';
import Laser_Dentistry_Treatment_In_Guntur from './DentalServices/Laser_Dentistry_Treatment_In_Guntur';
import Invisible_Aligners_And_Braces_Treatment_In_Guntur from './DentalServices/Invisible_Aligners_And_Braces_Treatment_In_Guntur';
import Veneers_Treatment_In_Guntur from './DentalServices/Veneers_Treatment_In_Guntur';
import Implants_Treatment_In_Guntur from './DentalServices/Implants_Treatment_In_Guntur';
import Zirconium_Crowns_In_Guntur from './DentalServices/Zirconium_Crowns_In_Guntur';
import Flap_Surgeries_Treatment_In_Guntur from './DentalServices/Flap_Surgeries_Treatment_In_Guntur';
import Cleft_Lip_and_Cleft_Palate_Surgeries_In_Guntur from './DentalServices/Cleft_Lip_and_Cleft_Palate_Surgeries_In_Guntur';
import Composite_Restorations_Treatment_In_Guntur from './DentalServices/Composite_Restorations_Treatment_In_Guntur';
import Mandibular_and_Maxillary_Surgeries_In_Guntur from './DentalServices/Mandibular_and_Maxillary_Surgeries_In_Guntur';
import Impactions_treatment_In_Guntur from './DentalServices/impactions_treatment_In_Guntur';
import DentalBlogs from './Blogs/DentalBlogs';
import SkinBlogs from './Blogs/SkinBlogs';
import DentalImages from './Images/DentalImages';
import Dental_Videos from './Videos/Dental_Videos';
import Skin_Videos from './Videos/Skin_Videos';
import Skin_and_hair_care_images from './Images/Skin_and_hair_care_images';
import Five_Common_Signs_and_Symptoms_of_a_Tooth_Cavity from './Blogs/DentalBlogs/Five_Common_Signs_and_Symptoms_of_a_Tooth_Cavity';
import What_are_the_Benefits_of_IV_Glutathione from './Blogs/SkinBlogs/What_are_the_Benefits_of_IV_Glutathione';
import DrkPawanSreeHarish from './DentalAbout/DrkPawanSreeHarish';
import DrNVNikhitha from './DentalAbout/DrNVNikhitha';






function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/Dental_Care_Home" element={<Dental_Care_Home/>} />
        <Route path='/Skin_And_Hair_Care_Home' element={<Skin_And_Hair_Care_Home/>}/>
        <Route path='/Skin_Care_specialist_in_guntur' element={<Skin_Care_specialist_in_guntur/>}/>
        <Route path='/Hair_Treatment_In_Guntur' element={<Hair_Treatment_In_Guntur/>}/>
        <Route path='/Laser_Treatment_In_Guntur' element={<Laser_Treatment_In_Guntur/>}/>
        <Route path='/Medi_facial_treatment_In_Guntur' element={<Medi_facial_treatment_In_Guntur/>}/>
        <Route path='/PMU_Treatment_In_Guntur' element={<PMU_Treatment_In_Guntur/>}/>
        <Route path='/Eyebrows_Treatment_in_Guntur' element={<Eyebrows_Treatment_in_Guntur/>}/>
        <Route path='/Lips_Treatment_in_Guntur' element={<Lips_Treatment_in_Guntur/>}/>
        {/* <Route path='/Contact' element={<Contact/>}/> */}
        <Route path='/DentalContact' element={<DentalContact/>}/>
        <Route path='/Microdermabrasion_Treatment_In_Guntur' element={<Microdermabrasion_Treatment_In_Guntur/>}/>
        <Route path='/Acne_treatment_in_Guntur' element={<Acne_treatment_in_Guntur/>}/>
        <Route path='/Customized_Skin_Care_Treatment_In_Guntur' element={<Customized_Skin_Care_Treatment_In_Guntur/>}/>
        <Route path='/Wart_Removal_Treatment_in_Guntur' element={<Wart_Removal_Treatment_in_Guntur/>}/>
        <Route path='/Melasma_Management_Treatment_In_Guntur' element={<Melasma_Management_Treatment_In_Guntur/>}/>
        <Route path='/PRP_Treatment_In_Guntur' element={<PRP_Treatment_In_Guntur/>}/>
        <Route path='/Biotin_And_PRP_treatment_in_Guntur' element={<Biotin_And_PRP_treatment_in_Guntur/>}/>
        <Route path='/GFC_Hair_Treatment_in_Guntur' element={<GFC_Hair_Treatment_in_Guntur/>}/>
        <Route path='/Alopecia_Areata_treatment_in_Guntur' element={<Alopecia_Areata_treatment_in_Guntur/>}/>
        <Route path='/Stem_Cell_Therapy_in_Guntur' element={<Stem_Cell_Therapy_in_Guntur/>}/>
        <Route path='/Hair_Transplantation_treatment_in_Guntur' element={<Hair_Transplantation_treatment_in_Guntur/>}/>
        <Route path='/Dandruff_Treatment_in_Guntur' element={<Dandruff_Treatment_in_Guntur/>}/>
        <Route path='/Acne_And_Acne_Marks_Treatment_In_Guntur' element={<Acne_And_Acne_Marks_Treatment_In_Guntur/>}/>
        <Route path='/Pigmentation_Treatment_in_Guntur' element={<Pigmentation_Treatment_in_Guntur/>}/>
        <Route path='/Freckles_Treatment_in_Guntur' element={<Freckles_Treatment_in_Guntur/>}/>
        <Route path='/Wrinkless_Treatment_In_Guntur' element={<Wrinkless_Treatment_In_Guntur/>}/>
        <Route path='/Under_Dark_Circles_Treatment_In_Guntur' element={<Under_Dark_Circles_Treatment_In_Guntur/>}/>
        <Route path='/Skin_Tightening_Treatment_In_Guntur' element={<Skin_Tightening_Treatment_In_Guntur/>}/>
        <Route path='/Hair_Removal_Treatment_in_Guntur' element={<Hair_Removal_Treatment_in_Guntur/>}/>
        <Route path='/Tattoo_Remova_Treatment_in_Guntur' element={<Tattoo_Remova_Treatment_in_Guntur/>}/>
        <Route path='/Hydra_Facial_Treatment_In_Guntur' element={<Hydra_Facial_Treatment_In_Guntur/>}/>
        <Route path='/OxyGeneo_Facial_Treatment_In_Guntur' element={<OxyGeneo_Facial_Treatment_In_Guntur/>}/>
        <Route path='/Carbon_Peel_Laser_Treatment_In_Guntur' element={<Carbon_Peel_Laser_Treatment_In_Guntur/>}/>
        <Route path='/Chemical_Peels_Treatment_In_Guntur' element={<Chemical_Peels_Treatment_In_Guntur/>}/>
        <Route path='/PDO_COG_Threads_Treatment_In_Guntur' element={<PDO_COG_Threads_Treatment_In_Guntur/>}/>
        <Route path='/Lip_Fillers_Treatment_In_Guntur' element={<Lip_Fillers_Treatment_In_Guntur/>}/>
        <Route path='/Under_Eye_Fillers_Treatment_In_Guntur' element={<Under_Eye_Fillers_Treatment_In_Guntur/>}/>
        <Route path='/Botox_Treatment_In_Guntur' element={<Botox_Treatment_In_Guntur/>}/>
        <Route path='/Barbie_Botox_treatment_In_Guntur' element={<Barbie_Botox_treatment_In_Guntur/>}/>
        <Route path='/Baby_Botox_Treatment_In_Guntur' element={<Baby_Botox_Treatment_In_Guntur/>}/>
        <Route path='/Nano_needling_Treatment_In_Guntur' element={<Nano_needling_Treatment_In_Guntur/>}/>
        <Route path='/IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur' element={<IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur/>}/>
        <Route path='/IV_Lipolysis_Treatment_in_Guntur' element={<IV_Lipolysis_Treatment_in_Guntur/>}/>
        <Route path='/Microblading_Treatment_In_Guntur' element={<Microblading_Treatment_In_Guntur/>}/>
        <Route path='/Micro_Pigmentation_Treatment_In_Guntur' element={<Micro_Pigmentation_Treatment_In_Guntur/>}/>
        <Route path='/Ombre_Powder_Brows_Treatment_In_Guntur' element={<Ombre_Powder_Brows_Treatment_In_Guntur/>}/>
        <Route path='/Lip_Neutralisation_Treatment_In_Guntur' element={<Lip_Neutralisation_Treatment_In_Guntur/>}/>
        <Route path='/Semi_Permanent_Lip_Tint_Treatment_In_Guntur' element={<Semi_Permanent_Lip_Tint_Treatment_In_Guntur/>}/>
        <Route path='/SkinCareContact' element={<SkinCareContact/>}/>
        <Route path='/DentalFooter' element={<DentalFooter/>}/>
        <Route path='/Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur' element={<Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur/>}/>
        <Route path='/Smile_Designing_Treatment_In_Guntur' element={<Smile_Designing_Treatment_In_Guntur/>}/>
        <Route path='/Root_Canal_Treatment_in_Guntur' element={<Root_Canal_Treatment_in_Guntur/>}/>
        <Route path='/Full_Mouth_Rehabilitation_In_Guntur' element={<Full_Mouth_Rehabilitation_In_Guntur/>}/>
        <Route path='/Teeth_Extractions_Treatment_In_Guntur' element={<Teeth_Extractions_Treatment_In_Guntur/>}/>
        <Route path='/Laser_Dentistry_Treatment_In_Guntur' element={<Laser_Dentistry_Treatment_In_Guntur/>}/>
        <Route path='/Invisible_Aligners_And_Braces_Treatment_In_Guntur' element={<Invisible_Aligners_And_Braces_Treatment_In_Guntur/>}/>
        <Route path='/Veneers_Treatment_In_Guntur' element={<Veneers_Treatment_In_Guntur/>}/>
        <Route path='/Implants_Treatment_In_Guntur' element={<Implants_Treatment_In_Guntur/>}/>
        <Route path='/Zirconium_Crowns_In_Guntur' element={<Zirconium_Crowns_In_Guntur/>}/>
        <Route path='/Flap_Surgeries_Treatment_In_Guntur' element={<Flap_Surgeries_Treatment_In_Guntur/>}/>
        <Route path='/Cleft_Lip_and_Cleft_Palate_Surgeries_In_Guntur' element={<Cleft_Lip_and_Cleft_Palate_Surgeries_In_Guntur/>}/>
        <Route path='/Composite_Restorations_Treatment_In_Guntur' element={<Composite_Restorations_Treatment_In_Guntur/>}/>
        <Route path='/Mandibular_and_Maxillary_Surgeries_In_Guntur' element={<Mandibular_and_Maxillary_Surgeries_In_Guntur/>}/>
        <Route path='/Impactions_treatment_In_Guntur' element={<Impactions_treatment_In_Guntur/>}/>
        <Route path='/DentalBlogs' element={<DentalBlogs/>}/>
        <Route path='/SkinBlogs' element={<SkinBlogs/>}/>
        <Route path='/DentalImages' element={<DentalImages/>}/>
        <Route path='/Dental_Videos' element={<Dental_Videos/>}/>
        <Route path='/Skin_Videos' element={<Skin_Videos/>}/>
        <Route path='/Skin_and_hair_care_images' element={<Skin_and_hair_care_images/>}/>
        <Route path='/Five_Common_Signs_and_Symptoms_of_a_Tooth_Cavity' element={<Five_Common_Signs_and_Symptoms_of_a_Tooth_Cavity/>}/>
        <Route path='/What_are_the_Benefits_of_IV_Glutathione' element={<What_are_the_Benefits_of_IV_Glutathione/>}/>
        <Route path='/DrkPawanSreeHarish' element={<DrkPawanSreeHarish/>}/>
        <Route path='/DrNVNikhitha' element={<DrNVNikhitha/>}/>
      </Routes>   
    </BrowserRouter>

  );
}

export default App;
