import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'


const Skin_Care_specialist_in_guntur = () => {
  return (
    <>
      <SkinCareHeader />
      <div className='topmargin'></div>
        <div className="container-fluid strips " id="strip" style={{  display: 'flex', backgroundColor: "#d4296b", }}>
          <div className="col text-center strip1" style={{ color: 'white' }}>
            <div className="row mt-5" >
              <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>SKIN TREATMENT IN LOTUS DENTAL SKIN AND HAIR care</h3>
            </div>
            <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
              <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
            </ul>
          </div>
        </div>
        <div className='container mt-3'>
          <div className='row'>
            <p>Welcome to Lotus Dental Skin and Hair Care, your premier destination for skin rejuvenation in Guntur. Our expert dermatologists offer personalized treatments tailored to your unique needs, ensuring optimal results. From acne solutions to anti-aging therapies, we utilize advanced techniques and state-of-the-art technology to enhance your natural beauty. Whether you're seeking to address pigmentation issues, scars, or hair restoration, our comprehensive services have you covered. Experience the difference with Lotus Dental Skin and Hair Care – where your skin receives the exceptional care it deserves. Schedule your consultation today and embark on a journey to healthier, more radiant skin.</p>
            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
              <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                <img src='pmuImage.jpeg' alt=''></img>
                <a href='/PMU_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>PMU</b></a>
              </div>
            </div>
            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
              <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                <img src='microdermabrasion.jpeg' alt=''></img>
                <a href='/Microdermabrasion_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Microdermabrasion</b></a>
              </div>
            </div>

            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
              <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'98%' }}>
                <img src='acne-treatment.webp' alt='' style={{width:'100%',marginTop:'1%'}}></img>
                <a href='Acne_treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Acne Treatment</b></a>
              </div>
            </div>

            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
              <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'98%' }}>
                <img src='customizedSkinCare.webp' alt='' style={{width:'100%'}}></img>
                <a href='Customized_Skin_Care_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Customised Skin Care</b></a>
              </div>
            </div>

            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
              <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                <img src='wart-removal.jpg' alt='' style={{width:'100%'}}></img>
                <a href='Wart_Removal_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Wart Removal</b></a>
              </div>
            </div>

            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
              <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'97%' }}>
                <img src='Melasma.jpg' alt='' style={{width:'100%'}}></img>
                <a href='' className='text-center mt-2 mb-3' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Melasma Management</b></a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}

export default Skin_Care_specialist_in_guntur