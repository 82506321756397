import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com'
import { TbDeviceLandlinePhone } from "react-icons/tb";
import DentalHeader from '../Components/DentalHeader'
import Footer from '../Components/Footer';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

const DentalContact = () => {
  const form = useRef()

  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })
  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_d', 'template_dh', e.target, '-_ajGXv')
      .then((result) => {
        alert("message Sent Succesfully")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  return (
    <>
      <DentalHeader />
      <div className='topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Contact</h3>
          </div>
          <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
          </ul>
        </div>
      </div>

      <div className="container text-center con my-3 mt-5 mb-3" >
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "black" }}><b>Don't Hesitate To Contact with Us</b></span>
        </h3>
        <div className="row justify-content-center">
          <div className="card my-5" style={{ width: '18rem', margin: '15px', color: 'white', backgroundColor: '#d4296b', border: '4px solid #fff', boxShadow: '0 0 20px #6b6a6a', borderRadius: '10px' }}>
            <div className="card-body">
              <h5 className="card-title mb-3">Address</h5>
              <p className="card-text mt-5" style={{textTransform:'none',textAlign:'center'}}>Naidupeta 2nd line, Amaravathi Road, Guntur</p>
              <a href="" className="btn card-link"><FaFacebookF style={{color:'white'}} /></a>
              <a href="" className="btn card-link"><FaTwitter style={{color:'white'}} /></a>
            </div>
          </div>
          <div className="card my-5 " style={{ width: '18rem', margin: '15px', backgroundColor: '#d4296b', color: 'whitesmoke', border: '4px solid #fff', boxShadow: '0 0 20px #6b6a6a', borderRadius: '10px' }}>
            <div className="card-body">
              <h5 className="card-title mb-4">Contact Details</h5>
              {/* <p className="card-text" style={{ fontSize: '14px' }}><TbDeviceLandlinePhone />  9701490546</p> */}
              <p className="card-text" style={{ fontSize: '14px', marginTop: '-10px',textAlign:'center' }}>Whatsapp</p>
              <p className="card-text" style={{ fontSize: '14px', marginTop: '-10px',textAlign:'center' }}>Contact : 97014 90546</p>
              <p className="card-text" style={{ fontSize: '15px', marginTop: '-10px',textAlign:'center' }}>Book Appointment</p>
              <p className="card-text" style={{ fontSize: '14px', marginTop: '-10px',textAlign:'center' }}>Contact : 091041 02109</p>


              <a href="" className="btn card-link"><FaYoutube style={{color:'white'}} /></a>
              <a href="https://www.instagram.com/lotusdentalaestheticss/" className="btn card-link"><FaInstagram style={{color:'white'}}/></a>
            </div>
          </div>
        </div>
      </div>

      <div >
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#d4296b" }}>MESSAGE US</span>
        </h1>
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "black" }}><b>Drop Us Message For Any Query</b></span>
        </h3>

        {/* form submission .......*/}
        <form ref={form} onSubmit={submitHandler}>
          <div className="container border w-100 m-auto p-3 " style={{ borderRadius: '20px' }}  >
            <div className="row" >
              <div className="col-lg-6  my-3 ">
                <div className="form-group ">
                  <input type="text" value={Name} onChange={changeHandler} className="form-control" title='' placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3 ">
                <div className="form-group">
                  <input type="email" value={Email} onChange={changeHandler} className="form-control" title='' placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="form-group">
                  <input type="text" value={Number} onChange={changeHandler} className="form-control" title='' name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="form-group">
                  <input type="text" value={Subject} onChange={changeHandler} className="form-control" title='' name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 my-3">
                <div className="form-group">
                  <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="6" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <button type="submit" className="btn " title='' name="contact" style={{ backgroundColor: "#d4296b", color: "white" }} >Send Message</button>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>

            </div>

          </div>


        </form>

        {/* MAP....... */}

      </div>
      <div className="container mt-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d61271.34150585635!2d80.3689954!3d16.2994829!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a358bfbf6411ab9%3A0x7b048930cf9fc094!2sLotus%20Dental%20and%20Aestheticss%20%7C%7C%20Best%20Dental%20Clinic%20in%20Guntur!5e0!3m2!1sen!2sin!4v1715677430617!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0, borderRadius: '15px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <Footer/>

    </>
  )
}

export default DentalContact