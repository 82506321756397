import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader';
import Footer from '../Components/Footer';

const Eyebrows_Treatment_in_Guntur = () => {
  return (
    <>
    <SkinCareHeader />
    <div className='topmargin'></div>
    <div className="container-fluid strips " id="strip" style={{ display: 'flex', backgroundColor: "#d4296b", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
            <div className="row mt-5" >
                <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Eyebrows TREATMENT IN LOTUS DENTAL SKIN AND HAIR care</h3>
            </div>
            <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
            </ul>
        </div>
    </div>
    <div className='container mt-3'>
        <div className='row' style={{justifyContent:'center'}}>
            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'99%' }}>
                    <img src='microbladingImage.jpg' alt='' style={{width:'100%',marginTop:'0%'}}></img>
                    <a href='Microblading_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Micro Blading</b></a>
                </div>
            </div>
            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <img src='micropigmentation.jpg' alt='' style={{width:'100%'}}></img>
                    <a href='Micro_Pigmentation_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Micro Pigmentation</b></a>
                </div>
            </div>

            <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <img src='Ombre.jpg' alt='' style={{width:'88%'}}></img>
                    <a href='Ombre_Powder_Brows_Treatment_In_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Ombre Powder Brows</b></a>
                </div>
            </div>

          
        </div>
    </div>
    <Footer />
</>
  )
}

export default Eyebrows_Treatment_in_Guntur