import React from 'react'
import { FaFacebookF } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
// import Modal from 'react-bootstrap/Modal';
// import emailjs from 'emailjs-com'
// import {useRef,useState } from 'react';
// import {Link } from 'react-router-dom';
// import Form from 'react-bootstrap/Form';

const Layout = () => {
    // const form = useRef()
    // const [data, updateData] = useState({
    //     Name: "",
    //     Email: '',
    //     Number: '',
    //     Subject: '',
    //     Message: ''
    //   })
  
    // const { Name, Email, Number, Subject, Message } = data;
    
    // const changeHandler = e => {
    //   updateData({ ...data, [e.target.name]: [e.target.value] })
  
    // }
    // const submitHandler = e => {
    //   e.preventDefault();
  
    //   emailjs.sendForm('service_yb', 'template_o', e.target, '7Y-oXT2ydz')
    //     .then((result) => {
    //       alert("Successfully Booked Your Appointment ")
    //       updateData({
    //         Name: "",
    //         Email: "",
    //         Number: "",
    //         Subject: '',
    //         Message: ''
  
    //       })
  
    //     }, (error) => {
    //       console.log(error.text);
    //     });
  
    // }
  
    // const [show, setShow] = useState(false);
  
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
 
    return (
        <>
            <div className='sections'>
                <div className='fluid-container shadow-sm p-3 mb-5 bg-white rounded mb-5'>
                    <div className='row'>
                        <div className='col-md-7 col-sm-12'>
                            <center>
                                <img src='LotusLogo1.png' className='layoutImage'></img>
                            </center>
                        </div>
                        <div className='col-md-5 col-sm-12 '>
                            <center className='iconsection'>
                                <a href='https://www.facebook.com/Lotusdentalandaestheticss/' className='iconmenu'>
                                    <FaFacebookF className='icons facebook' />
                                </a>
                                <a href='https://www.google.com/maps/dir//Lotus+Dental+and+Aestheticss+%7C%7C+Best+Dental+Clinic+in+Guntur+Naidupet+2nd+lane+Amaravathi+Rd,+opp.+tvs+showroom+Guntur,+Andhra+Pradesh+522007/@16.3108034,80.4357811,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a358bfbf6411ab9:0x7b048930cf9fc094!2m2!1d80.4357811!2d16.3108034?entry=ttu' className='iconmenu'>
                                    <IoLocationOutline className='icons location' />
                                </a>
                                <a href='tel:97014 90546' className='iconmenu'>
                                    <FaWhatsapp className='icons Appointment' />
                                </a>
                                <a href='https://www.instagram.com/lotusdental_cosmetic_clinic/' className='iconmenu'>
                                    <RiInstagramFill className='icons Appointment' />
                                </a>
                            </center>
                        </div>
                    </div>
                </div>


                <div className='container mb-2'>
                    <h3 className="text-center" style={{ color: '#d4296b' }}>LOTUS DENTAL SKIN AND HAIR CARE</h3>
                    <p className='mt-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
                </div>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-6 col-sm-12 main' >
                            {/* <div className='container imageUnder mt-3'>
                                <img src='LotusLogo1.png' style={{ width: "35%" }}></img>
                            </div> */}
                            <div className='wrapper text-center col-md-12 col-sm-12 p-5' >
                                <div className='image card shadow-pink p-1 bg-grey rounded' >
                                    <img src='dentalImage.jpg' alt='skincareimage' style={{ width: '100%' }}></img>
                                    <div className='overlay'>
                                        <a href='/Dental_Care_Home' >CLICK HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className='container imageUnderText text-center  mb-4'>
                                <a href='/Dental_Care_Home' style={{ textDecoration: 'none', marginBottom: '15px', marginTop: '15px' }} className='blink_me'>CLICK HERE</a>
                                {/* <p className='mt-3 text-center'>At Lotus Dental Multispeciality Dental Hospital, we believe that everyone deserves a healthy and beautiful smile. Located in the heart of Guntur, our state-of-the-art facility is equipped with the latest technology and staffed by a team of highly skilled dental professionals dedicated to providing exceptional dental care.</p> */}
                            </div>

                        </div>


                        <div className='col-md-6 col-sm-12 main' >
                            {/* <div className='container mt-3 imageUnderText'>
                                <img src='skinlogo1.png' style={{ width: "35%" }}></img>
                            </div> */}
                            <div className='wrapper text-center col-md-12 col-sm-12 p-5' >
                                <div className='image card shadow-pink p-1 bg-grey rounded' >
                                    <img src='WEB SITE LOGO skin clinic new.png' alt='skincareimage' style={{ width: '100%' }}></img>
                                    <div className='overlay'>
                                        <a href='/Skin_And_Hair_Care_Home'>CLICK HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className='container imageUnderText text-center  mb-4'>
                                <a href='/Skin_And_Hair_Care_Home' style={{ textDecoration: 'none', marginBottom: '15px', marginTop: '15px' }} className='blink_me'>CLICK HERE</a>

                                {/* <p className='mt-3 text-center'>We Provide you with customised and advanced skin and hair treatments for both men and women.We believe in great skin and healthy hair doesn't happen by chance it happence with commitment and consultation.</p> */}
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className='sections1'>
                <div className='fluid-container shadow-sm p-3 mb-5 bg-white rounded mb-5'>
                    <div className='row'>
                        <div className='col-md-7 col-sm-12'>
                            <center className='centerImage text-center'>
                                <img src='lotus multispeciality hospital1.png' className='layoutImage'></img>
                            </center>
                        </div>
                        <div className='col-md-5 col-sm-12 '>
                            <center className='iconsection'>
                                <a className='iconmenu'>
                                    <FaFacebookF className='icons facebook' />
                                </a>
                                <a href='https://www.google.com/maps/dir//Lotus+Dental+and+Aestheticss+%7C%7C+Best+Dental+Clinic+in+Guntur+Naidupet+2nd+lane+Amaravathi+Rd,+opp.+tvs+showroom+Guntur,+Andhra+Pradesh+522007/@16.3108034,80.4357811,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a358bfbf6411ab9:0x7b048930cf9fc094!2m2!1d80.4357811!2d16.3108034?entry=ttu' className='iconmenu'>
                                    <IoLocationOutline className='icons location' />
                                </a>
                                <a href='tel:97014 90546' className='iconmenu'>
                                    <FaWhatsapp className='icons Appointment' />
                                </a>
                                <a href='https://www.instagram.com/lotusdentalaestheticss/' className='iconmenu'>
                                    <RiInstagramFill className='icons Appointment' />
                                </a>
                               
                            </center>
                        </div>
                    </div>
                </div>


                <div className='container mb-2'>
                    <h3 className="text-center" style={{ color: '#d4296b' }}>LOTUS DENTAL SKIN AND HAIR CARE</h3>
                    <p className='mt-3' style={{ borderTop: '1px solid rgb(234, 234, 234)' }}></p>
                </div>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-6 col-sm-12 main' >
                            {/* <div className='container imageUnder mt-3'>
                                <img src='LotusLogo1.png' style={{ width: "35%" }}></img>
                            </div> */}
                            <div className='wrapper text-center col-md-12 col-sm-12 p-5' >
                                <div className='image' >
                                    <img src='LotusLogo1.png' alt='skincareimage' style={{ width: '100%', backgroundImage: 'none' }}></img>
                                    <div className='overlay'>
                                        <a href='/Dental_Care_Home'>CLICK HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className='container imageUnderText text-center mb-5'>
                                <a href='/Dental_Care_Home' style={{ textDecoration: 'none', marginBottom: '15px', marginTop: '15px' }} className='blink_me'>CLICK HERE</a>
                                {/* <p className='mt-3 text-center'>At Lotus Dental Multispeciality Dental Hospital, we believe that everyone deserves a healthy and beautiful smile. Located in the heart of Guntur, our state-of-the-art facility is equipped with the latest technology and staffed by a team of highly skilled dental professionals dedicated to providing exceptional dental care.</p> */}
                            </div>

                        </div>


                        <div className='col-md-6 col-sm-12 main' >
                            {/* <div className='container mt-3 imageUnderText'>
                                <img src='skinlogo1.png' style={{ width: "35%" }}></img>
                            </div> */}
                            <div className='wrapper text-center col-md-12 col-sm-12 p-5' >
                                <div className='image' >
                                    <img src='WEB SITE LOGO  skin clinic Horizental copy.png' alt='skincareimage' style={{ width: '100%', backgroundImage: 'none',borderRadius:'10px 10px 10px 10px'}}></img>
                                    <div className='overlay'>
                                        <a href='/Skin_And_Hair_Care_Home'>CLICK HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className='container imageUnderText text-center mb-5'>
                                <a href='/Skin_And_Hair_Care_Home' style={{ textDecoration: 'none', marginBottom: '15px', marginTop: '15px' }} className='blink_me'>CLICK HERE</a>

                                {/* <p className='mt-3 text-center'>We Provide you with customised and advanced skin and hair treatments for both men and women.We believe in great skin and healthy hair doesn't happen by chance it happence with commitment and consultation.</p> */}
                            </div>

                        </div>
                    </div>
                </div>

            </div>
{/* 
            <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center' style={{ color: '#d4296b', textAlign: 'center' }}>Book An Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={form} onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
                            <div className="help-block with-errors"></div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
                            <div className="help-block with-errors"></div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
                            <div className="help-block with-errors"></div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
                            <div className="help-block with-errors"></div>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
                            <div className="help-block with-errors"></div>
                        </Form.Group>
                        <button type="submit" className="btn " name="contact" style={{ backgroundColor: "#d4296b", color: "whitesmoke" }} >Send Message</button>
                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                        <div className="clearfix"></div>
                    </Form>
                </Modal.Body>
            </Modal> */}

        </>
    )
}

export default Layout