import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'


const IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>IV-Glutathione Drip for Whole Body Whitening treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Lotus Dental Skin and Hair Care Hospital in Guntur proudly offers IV-Glutathione Drip for Whole Body Whitening, a revolutionary treatment to help you achieve brighter, more radiant skin from within.</p>
                        <h4 style={{color:'#d4296b'}}>What is IV-Glutathione Drip?</h4>
                        <p>IV-Glutathione Drip is a procedure that involves the intravenous administration of glutathione, a powerful antioxidant, directly into the bloodstream. Glutathione is known for its skin brightening properties, helping to reduce the appearance of dark spots, pigmentation, and uneven skin tone.</p>
                        <h4 style={{color:'#d4296b'}}>How Does it Work?</h4>
                <p>During the IV-Glutathione Drip session at Lotus Dental Skin and Hair Care Hospital, glutathione is delivered directly into the bloodstream through an IV drip. This allows for maximum absorption and effectiveness, ensuring that the antioxidant reaches every cell in the body. As a result, the skin is nourished from the inside out, leading to a more luminous and flawless complexion.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services'>
                        <img src='Glutathione-for-Skin-whitening.jpg' style={{width:'80%'}} alt=''></img>
                    </div>
                </div>

                <h4 style={{color:'#d4296b'}}>Benefits of IV-Glutathione Drip:</h4>
                <ul>
                    <li><b>Skin Brightening: </b>Glutathione helps lighten and brighten the skin, resulting in a more even and radiant complexion.</li>
                    <li><b>Anti-Aging Effects: </b>By combating oxidative stress and free radical damage, IV-Glutathione Drip can also help reduce the signs of aging, such as fine lines and wrinkles.</li>
                    <li><b>Detoxification: </b>Glutathione supports the body's natural detoxification process, helping to eliminate toxins and impurities that can dull the skin.</li>
                </ul>
                <h4 style={{color:'#d4296b'}}>Why Choose Lotus Dental Skin and Hair Care Hospital?</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, we are committed to providing safe and effective treatments tailored to your individual needs. Our experienced healthcare professionals will ensure that you receive personalized care and achieve the best possible results.</p>
            </div>

            <Footer />
        </>
    )
}

export default IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur