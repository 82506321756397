import React from 'react'
import DentalHeader from '../Components/DentalHeader'
import DentalFooter from '../Components/DentalFooter'


const Mandibular_and_Maxillary_Surgeries_In_Guntur = () => {
    return (
        <>
            <DentalHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Mandibular and maxillary surgeries</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Lotus Dental Skin and Hair Care Hospital in Guntur offers advanced mandibular and maxillary surgeries to address a wide range of oral and maxillofacial conditions. Our skilled team of oral and maxillofacial surgeons, along with multidisciplinary specialists, are committed to providing comprehensive care and personalized treatment plans tailored to each patient's unique needs.</p>
                        <h4 style={{color:'#d4296b'}}>What are Mandibular and Maxillary Surgeries?</h4>
                        <p>Mandibular and maxillary surgeries are surgical procedures performed to correct various conditions affecting the jawbone (mandible) and the upper jaw (maxilla). These surgeries may be necessary to treat congenital abnormalities, traumatic injuries, developmental issues, tumors, infections, or functional problems related to the jaw and facial structures.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services mb-2'>
                        <img src='maxillary-mandibular.jpg' alt='' style={{ width: '100%' }}></img>
                    </div>
                </div>
                <h4 style={{color:'#d4296b'}}>Key Features of Mandibular and Maxillary Surgeries at Lotus Dental Skin and Hair Care Hospital:</h4>
                <ul>
                    <li><b>Comprehensive Evaluation: </b>Before recommending mandibular or maxillary surgery, our team conducts a thorough evaluation of the patient's oral health, medical history, and diagnostic imaging results. This evaluation helps us determine the underlying cause of the condition and develop an appropriate treatment plan.</li>
                    <li><b>Customized Treatment: </b>Each mandibular and maxillary surgery is tailored to the specific needs of the patient. Our experienced surgeons utilize advanced techniques and technology to achieve precise and predictable outcomes while minimizing risks and complications.</li>
                    <li><b>Treatment Options: </b>Our hospital offers a wide range of mandibular and maxillary surgeries, including orthognathic surgery (corrective jaw surgery), dental implants, bone grafting, sinus lift procedures, cyst removal, tumor resection, and trauma reconstruction. We work closely with each patient to determine the most appropriate surgical approach based on their individual needs and goals.</li>
                    <li><b>State-of-the-Art Facilities: </b>Lotus Dental Skin and Hair Care Hospital is equipped with state-of-the-art surgical facilities and advanced medical technology to ensure the highest standards of safety, comfort, and effectiveness during mandibular and maxillary surgeries. Our dedicated team of surgeons and support staff are committed to providing compassionate care and support throughout the surgical process.</li>
                    <li><b>Postoperative Care: </b>Following mandibular or maxillary surgery, our team provides comprehensive postoperative care instructions and closely monitors the patient's progress during the recovery period. We offer ongoing support and follow-up appointments to ensure optimal healing and long-term success.</li>
                </ul>
                <h4 style={{color:'#d4296b'}}>Transform Your Oral Health with Mandibular and Maxillary Surgeries:</h4>
                <p>If you are experiencing issues with your jawbone or upper jaw, Mandibular and Maxillary Surgeries at Lotus Dental Skin and Hair Care Hospital in Guntur may be the solution you need to restore your oral health and improve your quality of life. </p>

            </div>

            <DentalFooter />
        </>
    )
}

export default Mandibular_and_Maxillary_Surgeries_In_Guntur