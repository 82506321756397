import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'


const Microblading_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Microblading treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Lotus Dental Skin and Hair Care Hospital in Guntur welcomes you to experience the art of Microblading, a semi-permanent cosmetic procedure that enhances the appearance of eyebrows with natural-looking results.</p>
                        <h4 style={{color:'#d4296b'}}>What is Microblading?</h4>
                        <p>Microblading, also known as eyebrow embroidery or microstroking, is a specialized technique that involves the manual application of pigment into the skin using a handheld tool with fine needles. This procedure creates crisp, hair-like strokes that mimic the appearance of natural eyebrow hairs, resulting in fuller and more defined brows.</p>
                        <h4 style={{color:'#d4296b'}}>How Does it Work?</h4>
                <p>During a Microblading session at Lotus Dental Skin and Hair Care Hospital, our skilled aesthetic professionals will begin by consulting with you to understand your desired brow shape and color. Once the desired outcome is determined, a topical numbing cream will be applied to ensure your comfort during the procedure.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services mt-4 mb-2'>
                        <img src='microbladingImage.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                </div>
     
                <p>Using a precise handheld tool, our trained Microblading artist will carefully implant pigment into the superficial layers of the skin, creating individual strokes that resemble natural eyebrow hairs. The result is beautifully shaped and defined eyebrows that enhance your facial features and frame your eyes.</p>
                <h4 style={{color:'#d4296b'}}>Benefits of Microblading:</h4>
                <ul>
                    <li><b>Natural-Looking Results: </b>Microblading creates realistic, hair-like strokes that blend seamlessly with your natural brows, resulting in a natural-looking appearance.</li>
                    <li><b>Long-Lasting: </b>With proper care and maintenance, Microblading can last up to 1-3 years, providing long-lasting results that eliminate the need for daily eyebrow makeup application.</li>
                    <li><b>Customized Design: </b>Each Microblading treatment at Lotus Dental Skin and Hair Care Hospital is tailored to your unique facial features and preferences, ensuring personalized results that complement your overall look.</li>
                </ul>
            </div>

            <Footer />
        </>
    )
}

export default Microblading_Treatment_In_Guntur