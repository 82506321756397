import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const Dandruff_Treatment_in_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Dandruff Treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Are you tired of dealing with pesky dandruff that leaves your scalp itchy, flaky, and uncomfortable? At Lotus Dental Skin and Hair Care Hospital in Guntur, we offer comprehensive dandruff treatment solutions to help you achieve a healthy, dandruff-free scalp and hair. Say goodbye to dandruff and hello to confidence with our specialized treatment options.</p>

                        <h4 style={{ color: '#d4296b' }}>Understanding Dandruff:</h4>
                        <p>Dandruff is a common scalp condition characterized by the shedding of dead skin cells from the scalp, often resulting in visible flakes and itchiness. It can be caused by various factors, including dry scalp, oily skin, fungal infections, and sensitivity to hair care products.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services mt-2 mb-2'>
                        <img src='dandruff Treatment.jpg' alt='' style={{width:'90%'}}></img>
                    </div>
                </div>
                <h4 style={{ color: '#d4296b' }}>Our Approach to Dandruff Treatment:</h4>
                <p>At Lotus Dental Skin and Hair Care Hospital, we take a holistic approach to dandruff treatment, addressing the underlying causes of dandruff and tailoring treatment plans to each patient's specific needs and scalp condition. Our experienced dermatologists and hair care specialists will conduct a thorough evaluation to determine the most effective course of action.</p>

                <h4 style={{ color: '#d4296b' }}>Effective Dandruff Treatment Options:</h4>
                <ul style={{ textAlign: 'justify' }}>
                    <li><b>Medicated Shampoos: </b>We offer a range of medicated shampoos containing active ingredients such as ketoconazole, selenium sulfide, zinc pyrithione, or coal tar to help control dandruff, reduce scalp inflammation, and inhibit the growth of dandruff-causing fungi.</li>
                    <li><b>Topical Treatments: </b>Our dermatologists may recommend topical treatments such as corticosteroid creams or lotions to reduce inflammation and itching associated with dandruff and promote healing of the scalp.</li>
                    <li><b>Antifungal Agents: </b>In cases where dandruff is caused by fungal infections such as seborrheic dermatitis, we may prescribe antifungal agents in the form of shampoos, creams, or oral medications to eliminate the underlying fungal infection and prevent recurrence.</li>
                    <li><b>Scalp Treatments: </b>We offer specialized scalp treatments that help exfoliate dead skin cells, unclog hair follicles, and balance scalp oil production, promoting a healthier scalp environment and reducing dandruff.</li>
                    <li><b>Nutritional Counseling: </b>Our experts may provide nutritional counseling to address any dietary deficiencies or imbalances that may contribute to dandruff, ensuring that your scalp receives the essential nutrients it needs for optimal health.</li>
                </ul>

            </div>

            <Footer />
        </>
    )
}

export default Dandruff_Treatment_in_Guntur