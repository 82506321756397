import React from 'react'
import DentalHeader from '../Components/DentalHeader'
import DentalFooter from '../Components/DentalFooter'


const DrkPawanSreeHarish = () => {
    return (
        <>
            <DentalHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Dr.K PawanSreeHarish</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p>Dr. Pawan Sree Harish, BDS, FAD, is a Cosmetic Dental Surgeon specializing in aesthetic dental procedures. His qualifications include a Bachelor of Dental Surgery (BDS) and a Fellowship in Aesthetic Dentistry (FAD), reflecting expertise in both general and cosmetic dental care.</p>
                    </div>
                    <div className='col-md-5 col-sm-12 services mb-2'>
                        <img src='PawanSreeHarish.jpg' alt='' style={{ width: '90%' }}></img>
                    </div>
                </div>
            </div>

            <DentalFooter />
        </>
    )
}

export default DrkPawanSreeHarish