import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'


const Hair_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
                <div className="container-fluid strips " id="strip" style={{  display: 'flex', backgroundColor: "#d4296b", }}>
                    <div className="col text-center strip1" style={{ color: 'white' }}>
                        <div className="row mt-5" >
                            <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Hair TREATMENT IN LOTUS DENTAL SKIN AND HAIR care</h3>
                        </div>
                        <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                            <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                        </ul>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <p>Discover luxurious hair care at Lotus Dental Skin and Hair Care in Guntur. Our skilled specialists offer tailored solutions for all your hair concerns, from hair loss to scalp conditions. Using cutting-edge techniques and advanced therapies such as PRP (Platelet-Rich Plasma) therapy and hair transplants, we rejuvenate and restore your hair's natural vitality. Whether you seek to combat baldness, improve hair texture, or stimulate growth, our personalized approach ensures exceptional results. Trust Lotus Dental Skin and Hair Care for comprehensive hair treatments that enhance your confidence and leave you with luscious locks. Schedule your consultation today and unlock the secret to beautiful, healthy hair.</p>
                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='PRPIMAGE.jpeg' alt='' style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='/PRP_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>PRP</b></a>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='prp-with-biotin.webp' alt='' style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='/Biotin_And_PRP_treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Biotin+PRP</b></a>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='GFC-PRP.jpg' alt='' style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='/GFC_Hair_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>GFC</b></a>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='Alopecia-Areata.jpg' alt=''style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='Alopecia_Areata_treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Alopecia Areata</b></a>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='stem-cell-therapy.webp' alt='' style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='/Stem_Cell_Therapy_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Stem Cell Therapy</b></a>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='hair-transplant.jpg' alt='' style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='/Hair_Transplantation_treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Hair Transplantation</b></a>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',height:'100%'}}>
                                <img src='dandruff Treatment.jpg' alt='' style={{width:'100%'}} className='treatmentImages'></img>
                                <a href='Dandruff_Treatment_in_Guntur' className='text-center mt-3 mb-2' style={{ textDecoration: 'none', color: '#d4296b' }}><b>Dandruff Treatment</b></a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
        </>
    )
}

export default Hair_Treatment_In_Guntur