import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const Microdermabrasion_Treatment_In_Guntur = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>microdermabrasion Treatment</h3>
                    </div>
                    <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Skin_And_Hair_Care_Home" style={{ color: 'white' }}>Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Experience the ultimate skin rejuvenation with microdermabrasion at Lotus Dental Skin and Hair Care Hospital in Guntur. Our advanced treatment is designed to exfoliate, refresh, and revitalize your skin, leaving you with a radiant and youthful glow.</p>
                        <h4 style={{ color: '#d4296b' }}>What is Microdermabrasion?</h4>
                        <p style={{ textAlign: 'justify' }}>Microdermabrasion is a non-invasive cosmetic procedure that gently removes the outermost layer of dead skin cells, revealing smoother, healthier-looking skin underneath. Using a specialized device, tiny crystals are sprayed onto the skin to gently exfoliate and buff away imperfections.</p>
                        <h4 style={{ color: '#d4296b' }}>Benefits of Microdermabrasion:</h4>
                        <ul>
                            <li>Improves skin texture and tone</li>
                            <li>Reduces the appearance of fine lines and wrinkles</li>
                            <li>Minimizes pore size</li>
                            <li>Diminishes acne scars and hyperpigmentation</li>
                            <li>Stimulates collagen production for firmer skin</li>
                            <li>Enhances the effectiveness of skincare products</li>
                        </ul>
                    </div>
                    <div className='col-md-5 col-sm-12 services'>
                        <img src='microdermabrasion.jpeg' alt=''></img>
                    </div>

                    <h4 style={{ color: '#d4296b' }}>Why Choose Lotus Dental Skin and Hair Care Hospital?</h4>
                    <p style={{ textAlign: 'justify' }}>At Lotus Dental Skin and Hair Care Hospital, we prioritize your comfort and satisfaction. Our experienced dermatologists and skincare professionals customize each microdermabrasion treatment to address your unique skin concerns. With state-of-the-art equipment and a commitment to excellence, we ensure optimal results with minimal downtime.</p>
                    <h4 style={{ color: '#d4296b' }}>Treatment Process:</h4>
                    <p style={{ textAlign: 'justify' }}><b>Consultation: </b>Our skincare experts will assess your skin type and discuss your treatment goals to develop a personalized plan.</p>
                    <p style={{ textAlign: 'justify' }}><b>Preparation: </b>Your skin will be thoroughly cleansed to remove any makeup, oils, or impurities.</p>
                    <p style={{ textAlign: 'justify' }}><b>Microdermabrasion: </b>Using a handheld device, gentle abrasion and suction will be applied to exfoliate the outer layer of your skin.</p>
                    <p style={{ textAlign: 'justify' }}><b>Hydration: </b>A soothing moisturizer or serum will be applied to hydrate and nourish your skin.</p>
                    <p style={{ textAlign: 'justify' }}><b>Sun Protection: </b>It is essential to protect your skin from UV rays post-treatment. Our experts will recommend suitable sunscreen products.</p>
                    <h4 style={{ color: '#d4296b' }}>Aftercare Tips:</h4>
                    <ul style={{ textAlign: 'justify',marginLeft:'3%' }}>
                        <li>Avoid direct sunlight and use sunscreen regularly.</li>
                        <li>Moisturize your skin to maintain hydration.</li>
                        <li>Refrain from using harsh skincare products or exfoliants for a few days.</li>
                        <li>Follow any additional instructions provided by our skincare specialists.</li>
                    </ul>
                 

                </div>
            </div>

            <Footer />
        </>
    )
}

export default Microdermabrasion_Treatment_In_Guntur