import React from 'react'
import SkinCareHeader from './SkinCareHeader'
import Carousel from 'react-bootstrap/Carousel';
import Footer from './Footer';
import {
    MDBCard,
    MDBCardBody,
    MDBCarousel,
    MDBCarouselItem,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
} from "mdb-react-ui-kit";

const Skin_And_Hair_Care_Home = () => {
    return (
        <>
            <SkinCareHeader />
            <div className='topmargin'></div>
            <Carousel data-bs-theme="dark">
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="skinbanner1.jpg"
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                        <h5>First slide label</h5>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="skinbanner2.jpg"
                        alt="Second slide"
                    />
                    {/* <Carousel.Caption>
                        <h5>Second slide label</h5>
                        <p style={{ textAlign: 'justify' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="skinbanner3.jpg"
                        alt="Third slide"
                    />
                    {/* <Carousel.Caption>
                        <h5>Third slide label</h5>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>

            <div className='container'>
                <h2 style={{ color: '#d4296b' }}>LOTUS DENTAL SKIN AND HAIR CARE IN GUNTUR</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '40%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px' }} className='mb-3'></div>
                <div className='row'>
                    <div className='col-md-5 col-sm-12'>
                        <img src='' alt=''></img>
                    </div>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>At Lotus Dental Skin and Hair Care, we believe everyone deserves to look and feel their best. Conveniently located in Guntur, our state-of-the-art facility offers a comprehensive range of services to meet all your dental, skin, and hair care needs. Our dedicated team of professionals is committed to providing personalized care in a comfortable and welcoming environment.</p>
                        <p style={{ textAlign: 'justify' }}>Our skin care services are designed to rejuvenate and enhance your natural beauty. We offer treatments for acne, scars, pigmentation, and aging skin. Our advanced procedures include chemical peels, microdermabrasion, laser treatments, and Botox injections. Our team also provides personalized skin care regimens to help you maintain healthy, glowing skin.</p>
                        <p style={{ textAlign: 'justify' }}>We provide a variety of hair care treatments aimed at addressing hair loss, thinning hair, and scalp conditions. Our services include hair transplants, PRP therapy, and advanced hair restoration techniques. We also offer customized treatment plans to ensure the best results for your hair health and appearance.</p>
                    </div>
                </div>
            </div>

            <div className='container'>
                <h2 style={{ color: '#d4296b' }}>Treatments</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '10%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>

                <div className='row'>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card Homecards text-center' style={{ backgroundColor: '#d4296b',height:'100%' }}>
                            <h4 style={{ color: 'white', marginTop: '10%' }}>SKIN</h4>
                            <div style={{ borderBottom: '1px solid white', width: '30%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                            <p style={{ color: 'white',textAlign:'center' }}>Welcome to Lotus Dental Skin and Hair Care, your premier destination for skin rejuvenation in Guntur. Our expert dermatologists offer personalized treatments tailored to your unique needs, ensuring optimal results.</p>
                            <a href='Skin_Care_specialist_in_guntur' className='mb-3'><button> Click Here</button></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card Homecards text-center' style={{ backgroundColor: '#009fa3' ,height:'100%'}}>
                            <h4 style={{ color: 'white', marginTop: '10%' }}>HAIR</h4>
                            <div style={{ borderBottom: '1px solid white', width: '30%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                            <p style={{ color: 'white',textAlign:'center'}}>Discover luxurious hair care at Lotus Dental Skin and Hair Care in Guntur. Our skilled specialists offer tailored solutions for all your hair concerns, from hair loss to scalp conditions. Using cutting-edge techniques and advanced therapies such as PRP therapy and hair transplants</p>
                            <a href='Hair_Treatment_In_Guntur' className='mb-3'><button> Click Here</button></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card Homecards text-center' style={{ backgroundColor: '#d4296b',height:'100%' }}>
                            <h4 style={{ color: 'white', marginTop: '10%' }}>LASER</h4>
                            <div style={{ borderBottom: '1px solid white', width: '30%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                            <p style={{ color: 'white',textAlign:'center' }}>Experience the power of laser technology at Lotus Dental Skin and Hair Care in Guntur. Our advanced laser treatments offer safe and effective solutions for various skin concerns.</p>
                            <a href='Laser_Treatment_In_Guntur' className='mt-5'><button> Click Here</button></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card Homecards text-center' style={{ backgroundColor: '#009fa3' ,height:'100%'}}>
                            <h4 style={{ color: 'white', marginTop: '10%' }}>COSMETOLOGY</h4>
                            <div style={{ borderBottom: '1px solid white', width: '70%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                            <p style={{ color: 'white',textAlign:'center' }}>Indulge in the ultimate skin pampering experience with Medi Facial treatments at Lotus Dental Skin and Hair Care in Guntur. Our Medi Facial treatments combine the luxury of a spa facial with the expertise of medical-grade skincare to deliver unparalleled results.</p>
                            <a href='Medi_facial_treatment_In_Guntur' className='mb-3'><button> Click Here</button></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
                <h2 style={{color:'#d4296b'}}>Meet Our Expert</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '10%', marginLeft: 'auto', marginRight: 'auto' }} className='mb-3'></div>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <p><b>Dr Name</b></p>
                        <p></p>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='' alt='Doctor image'></img>
                    </div>
                </div>
            </div>

             {/* testmonials */}

             <div className='container mt-5'>
                <h2 style={{color:'#d4296b'}}>Testmonials</h2>
                <div style={{ borderBottom: '0.1px solid #d4296b', width: '10%', marginLeft: 'auto', marginRight: 'auto' }} className=''></div>
                <div className=''>
                    <MDBContainer fluid className="py-3 gradient-custom">
                        <MDBRow className="d-flex justify-content-center" start={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'}}>
                            <MDBCol md="12">
                                <div className="text-center mb-4 pb-2">
                                    <MDBIcon fas icon="quote-left" size="3x" className="text-white" />
                                </div>
                                <MDBCard>
                                    <MDBCardBody className="px-4 py-5">
                                        <MDBCarousel showIndicators showControls dark>

                                            <MDBCarouselItem className="active">
                                                <MDBRow className="d-flex justify-content-center">
                                                    <MDBCol lg="10" xl="8">
                                                        <MDBRow>
                                                            <MDBCol
                                                                lg="4"
                                                                className="d-flex justify-content-center"
                                                            >
                                                                <img
                                                                    src="Profile-PNG-Clipart.png"
                                                                    className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                                                    alt="woman avatar"
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol
                                                                md="9"
                                                                lg="7"
                                                                xl="8"
                                                                className="text-center text-lg-start mx-auto mx-lg-0"
                                                            >
                                                                <h4 className="mb-4">
                                                                    Mukesh
                                                                </h4>
                                                                <p className="mb-0 pb-3">
                                                                These incidents made me very depressed. Thank God I finally found solution in LOTUS DENTAL SKIN AND HAIR CARE
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem>
                                                <MDBRow className="d-flex justify-content-center">
                                                    <MDBCol lg="10" xl="8">
                                                        <MDBRow>
                                                            <MDBCol
                                                                lg="4"
                                                                className="d-flex justify-content-center"
                                                            >
                                                                <img
                                                                    src="Profile-PNG-Clipart.png"
                                                                    className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                                                    alt="woman avatar"
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol
                                                                md="9"
                                                                lg="7"
                                                                xl="8"
                                                                className="text-center text-lg-start mx-auto mx-lg-0"
                                                            >
                                                                <h4 className="mb-4">
                                                                    Mounika
                                                                </h4>
                                                                <p className="mb-0 pb-3">
                                                                I visited this hospital for acne.Doctor patiently listen to my concerns and given me the treatment regarding with my skin concerns. And hospital staff is friendly and taken good care 
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem>
                                                <MDBRow className="d-flex justify-content-center">
                                                    <MDBCol lg="10" xl="8">
                                                        <MDBRow>
                                                            <MDBCol
                                                                lg="4"
                                                                className="d-flex justify-content-center mb-4"
                                                            >
                                                                <img
                                                                    src="Profile-PNG-Clipart.png"
                                                                    className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                                                    alt="woman avatar"
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol
                                                                md="9"
                                                                lg="7"
                                                                xl="8"
                                                                className="text-center text-lg-start mx-auto mx-lg-0"
                                                            >
                                                                <h4 className="mb-4">
                                                                    Minakshi
                                                                </h4>
                                                                <p className="mb-0 pb-3">
                                                                I had my eyebrow microblading done at lotus skin and hair care, where expert technicians provided the service at a reasonable cost. The process was painless, effective, and safe, delivering long-lasting and natural-looking results. I highly recommend lotus skin and hair care for eyebrow microblading
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCarouselItem>

                                        </MDBCarousel>
                                    </MDBCardBody>
                                </MDBCard>
                                <div className="text-center mt-4 pt-2">
                                    <MDBIcon fas icon="quote-right" size="3x" className="text-white" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Skin_And_Hair_Care_Home