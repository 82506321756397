import React from 'react'
import { TfiEmail } from "react-icons/tfi";
import { FiPhoneCall } from "react-icons/fi";
import { RiHomeOfficeLine } from "react-icons/ri";

const DentalFooter = () => {
  return (
    
        <> 
        <div className='footer'>
            <div className='container' >
                <div className='row'>
                    <div className='col-md-3 col-sm-12 text-center'>
                        <img src='LotusLogo1.png' alt=''></img>
                        <p className='footerText'> At Lotus Dental Skin and Hair Care, we believe everyone deserves to look and feel their best. Conveniently located in Guntur, our state-of-the-art facility offers a comprehensive range of services to meet all your dental, skin, and hair care needs.</p>
                    </div>
                    <div className='col-md-6 col-sm-12 text-center'>
                        <h6>USEFULL LINKS</h6>
                        <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                       <ul style={{textAlign:'left'}}>
                            <li><a href='/Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur' style={{color:'white'}}>Advanced Zoom Teeth Whitening</a></li>
                            <li><a href='/Smile_Designing_Treatment_In_Guntur' style={{color:'white'}}>Smile Designing</a></li>
                            <li><a href='/Veneers_Treatment_In_Guntur' style={{color:'white'}}>Veneers</a></li>
                            <li><a href='/Invisible_Aligners_And_Braces_Treatment_In_Guntur' style={{color:'white'}}>Invisible Aligners & Braces</a></li>
                            <li><a href='/Full_Mouth_Rehabilitation_In_Guntur' style={{color:'white'}}>Full Mouth Rehabilitation</a></li>
                            <li><a href='/Teeth_Extractions_Treatment_In_Guntur' style={{color:'white'}}>Teeth Extractions</a></li>
                        </ul>
                       </div>
                       <div className='col-md-6 col-sm-12'>
                       <ul style={{textAlign:'left'}}>
                       <li><a href='/Cleft_Lip_and_Cleft_Palate_Surgeries_In_Guntur' style={{color:'white'}}>Cleft Lip and Cleft Palate Surgeries</a></li>
                            <li><a href='/Laser_Dentistry_Treatment_In_Guntur' style={{color:'white'}}>Laser Dentistry</a></li>
                            <li><a href='/Root_Canal_Treatment_in_Guntur' style={{color:'white'}}>Root Canal Treatment</a></li>

                            <li><a href='/Implants_Treatment_In_Guntur' style={{color:'white'}}>Implants</a></li>
                            <li><a href='/Mandibular_and_Maxillary_Surgeries_In_Guntur' style={{color:'white'}}>Mandibular and Maxillary Surgeries</a></li>
                            <li><a href='/' style={{color:'white'}}></a></li>
                        </ul>
                       </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <h6>CONTACT</h6>
                        <p style={{textAlign:'left'}}><RiHomeOfficeLine />  Naidupeta 2nd line,Amaravathi Road,Guntur</p>
                        <p style={{textAlign:'left'}}><TfiEmail /> Lotusdental5@gmail.com</p>
                        <p style={{textAlign:'left'}}><FiPhoneCall /> 9701490546</p>
                    </div>
                </div>
            </div>
            </div>
         
        </>
    
  )
}

export default DentalFooter