
import React, { useState } from 'react'
import {useRef } from 'react';
import {Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './Dentalheader.css'
import { Col, Dropdown, Row } from 'react-bootstrap';


const DentalHeader = () => {
   // bookappointment script 
   const form = useRef()
   const [data, updateData] = useState({
       Name: "",
       Email: '',
       Number: '',
       Subject: '',
       Message: ''
     })
 
   const { Name, Email, Number, Subject, Message } = data;
   
   const changeHandler = e => {
     updateData({ ...data, [e.target.name]: [e.target.value] })
 
   }
   const submitHandler = e => {
     e.preventDefault();
 
     emailjs.sendForm('service_yb', 'template_o', e.target, '7Y-oXT2ydz')
       .then((result) => {
         alert("Successfully Booked Your Appointment ")
         updateData({
           Name: "",
           Email: "",
           Number: "",
           Subject: '',
           Message: ''
 
         })
 
       }, (error) => {
         console.log(error.text);
       });
 
   }
 
   const [show, setShow] = useState(false);
 
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
    // const [gallery, setGallery] = useState(false)
    // const [menubar, setmenubar] = useState(false)
    // const [services, setServices] = useState(false)
    // const [isOpen, setIsOpen] = useState(false);
  
    // const toggleMenu = () => {
    //   setIsOpen(!isOpen);
  return (
    <>
     <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar shadow-sm p-3 mb-5 bg-body rounded" fixed="top">
      <Container>
        <img src='LotusLogo1.png' alt='lotus logo'></img>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" navbarScroll>
            <Nav.Link href="/Dental_Care_Home" className="items">HOME</Nav.Link>
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic1">
                ABOUT US
              </Dropdown.Toggle>
              <Dropdown.Menu className="mega-menu1 mt-2">
                <Dropdown.Item href="DrkPawanSreeHarish" className='galleryitems1'>Dr.K PawanSreeHarish</Dropdown.Item>
                <Dropdown.Item href="DrNVNikhitha" className='galleryitems'>Dr.N V Nikhitha</Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
     
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic" className=''>
                SERVICES
              </Dropdown.Toggle>
              <Dropdown.Menu className="mega-menu mt-2">
                <div className="mega-menu-columns">
                  <div className="mega-menu-column col-sm-12">
                    <Dropdown.Item href="Root_Canal_Treatment_in_Guntur" className='megamenuitems'>Root Canal Treatment</Dropdown.Item>
                    <Dropdown.Item href="Implants_Treatment_In_Guntur"  className='megamenuitems'>Implants</Dropdown.Item>
                    <Dropdown.Item href="Laser_Dentistry_Treatment_In_Guntur" className='megamenuitems'>Laser Dentistry</Dropdown.Item>
                    <Dropdown.Item href="Smile_Designing_Treatment_In_Guntur" className='megamenuitems'>Smile Designing</Dropdown.Item>
                    <Dropdown.Item href="Zirconium_Crowns_In_Guntur" className='megamenuitems'>Zirconium Crowns</Dropdown.Item>
                  </div>
                  <div className="mega-menu-column col-sm-12">
                    
                    <Dropdown.Item href="Full_Mouth_Rehabilitation_In_Guntur" className='megamenuitems'>Full Mouth Rehabilitation</Dropdown.Item>
                    <Dropdown.Item href="Invisible_Aligners_And_Braces_Treatment_In_Guntur" className='megamenuitems'>Invisible Aligners & Braces</Dropdown.Item>
                    <Dropdown.Item href="/Advanced_Zoom_Teeth_Whitening_Treatment_In_Guntur" className='megamenuitems'>Advanced Zoom Teeth Whitening</Dropdown.Item>
                    <Dropdown.Item href="impactions_treatment_In_Guntur" className='megamenuitems'>Impactions</Dropdown.Item>
                    <Dropdown.Item href="Flap_Surgeries_Treatment_In_Guntur" className='megamenuitems'>Flap Surgeries</Dropdown.Item>
                  </div>

                  <div className="mega-menu-column col-sm-12">
                    <Dropdown.Item href="Mandibular_and_Maxillary_Surgeries_In_Guntur" className='megamenuitems'>Mandibular and maxillary Surgeries</Dropdown.Item>
                    <Dropdown.Item href="Cleft_Lip_and_Cleft_Palate_Surgeries_In_Guntur" className='megamenuitems'>Cleft Lip And Cleft Palate Surgeries</Dropdown.Item>
                    <Dropdown.Item href="Teeth_Extractions_Treatment_In_Guntur" className='megamenuitems'>Teeth Extractions</Dropdown.Item>
                    <Dropdown.Item href="Composite_Restorations_Treatment_In_Guntur" className='megamenuitems'>Composite Restorations</Dropdown.Item>
                    <Dropdown.Item href="Veneers_Treatment_In_Guntur" className='megamenuitems'>Veneers</Dropdown.Item>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic1">
                GALLERY
              </Dropdown.Toggle>
              <Dropdown.Menu className="mega-menu1 mt-2">
                <Dropdown.Item href="DentalBlogs" className='galleryitems1'>Blogs</Dropdown.Item>
                <Dropdown.Item href="DentalImages" className='galleryitems'>Images</Dropdown.Item>
                <Dropdown.Item href="Dental_Videos" className='galleryitems'>Videos</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <Nav>
            <Nav.Link href="DentalContact" className="items">CONTACT</Nav.Link>
            <Link to="#" ><button className="btn" id="btnchange" onClick={handleShow} >BOOK APPOINTMENT</button></Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: '#d4296b',textAlign:'center' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "#d4296b", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DentalHeader